.additional-serv {
  background-color: #27201c;
  padding: 0 0 4rem 0;
  @media (min-width: $media_tablet) {
    padding: 8rem 0;
  }
  .imageText-wrapper {
    gap: 2rem;
  }
  h2 {
    color: #f69e29;
    padding: 0 1rem;
    position: relative;
    z-index: 2;
    @media (min-width: $media_tablet) {
      max-width: 67rem;
      padding: 0 0 0 1rem;
      margin: 0 0 10rem auto;
    }
  }
  .imageText-column {
    position: relative;
    display: flex;
    flex-wrap: wrap-reverse;
    @media (min-width: $media_tablet) {
      display: block;
    }
    picture,
    img {
      width: 100%;
      margin-bottom: 4rem;
      height: auto;
      @media (min-width: $media_tablet) {
        margin-bottom: 0;
        position: absolute;
        bottom: 0;
        height: 90%;
        width: 100%;
      }
    }
  }
  .additional-serv_list {
    padding: 1rem;
    @media (min-width: $media_tablet) {
      max-width: 67rem;
      padding: 0 1rem 0 0;
    }
  }
  .additional-serv_list-item {
    padding: 2rem 1.8rem;
    margin-bottom: 1.8rem;
    background-color: #161210;
    border-right: 5px solid #e49f46;
    &:last-child {
      margin-bottom: 0;
    }
    @media (min-width: $large_desktop) {
      padding: 3rem;
    }
  }
  .additional-serv_list-header {
    color: #fff;
    font-size: 1.8rem;
    margin-bottom: 2rem;
  }
  .additional-serv_list-text {
    @media (min-width: $large_desktop) {
      font-size: 1.7rem;
    }
  }
}
