html {
  font-size: 62.5%;
  input[type='button'] {
    cursor: pointer;
  }
  input[disabled] {
    cursor: default;
  }
}
body * {
  box-sizing: border-box;
  // position: relative;
}
html,
body {
  height: 100%;
}
body {
  font-family: $font_regular;
  font-size: $font_size;
  line-height: 1.25;
  margin: 0;
  color: $color_text;
  min-width: 320px;
  background: #27201c;
}
main {
  background-color: #fff;
}
a {
  color: #ffffff;
  text-decoration: none;
  font-size: 1.5rem;
  text-align: center;
  &:active {
    outline: 0;
  }
  &:hover {
    outline: 0;
    text-decoration: none;
  }
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
h1,
h2,
h3 {
  font-family: $font_regular;
  font-weight: 300;
  color: #34303d;
  line-height: 1.25;
  text-align: left;
  text-transform: none;
  margin: 0 0 1.5rem;
}
h1 {
  font-size: 2.8rem;
  margin: 0 0 1.5rem;
  letter-spacing: -0.1rem;
  @media (min-width: $media_desktop) {
    letter-spacing: -0.2rem;
    font-size: 4.2rem;
  }
  @media (min-width: $large_desktop) {
    font-size: 7.2rem;
  }
}
h2 {
  font-size: 3.2rem;
  margin: 0 0 1rem;
  @media (min-width: $large_desktop) {
    margin: 0 0 3rem;
    letter-spacing: -0.2rem;
    font-size: 4.8rem;
  }
}
h3 {
  font-size: 2.4rem;
  font-weight: 100;
  line-height: 1.15;
  @media (min-width: $large_desktop) {
    margin: 0 0 3rem;
    font-size: 3.2rem;
    line-height: 1.25;
  }
}
p {
  margin: 0 0 0.5rem;
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1.5;
  @media (min-width: $large_desktop) {
    font-size: 1.7rem;
  }
}
.strong {
  font-weight: bold;
}
svg {
  display: block;
}
img {
  display: block;
  width: 100%;
  height: 100%;
}
sup {
  vertical-align: top;
  position: relative;
  top: -0.5em;
  font-size: 0.5em;
}
