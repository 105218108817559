.press-center {
  padding: 3rem 0 3rem;
  //margin-top: -7rem;
  @media (min-width: $media_tablet) {
    padding: 7rem 0 9rem;
    //margin-top: 0;
  }
  h2 {
    margin-bottom: 2.5rem;
    @media (min-width: $media_tablet) {
      margin-bottom: 3.5rem;
    }
  }
}
.navigate-desk-slider {
  margin-bottom: 2.4rem;
  @media (min-width: $media_tablet) {
    margin-bottom: 4rem;
  }
  ul {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .navigate-slider-item {
    a {
      background-color: transparent;
      color: #868078;
      text-decoration: none;
      font-size: 1.4rem;
      padding: 0.8rem 1.5rem;
      margin-right: 1rem;
      transition: color 0.3s ease, background-color 0.3s ease;
      @media (min-width: $media_tablet) {
        font-size: 1.8rem;
      }
      &:hover {
        background-color: #f69e29;
        color: #161210;
      }
    }
    &.active-item {
      a {
        background-color: #f69e29;
        color: #161210;
      }
    }
  }
}
