.footer {
  background-color: #161210;
  padding: 8rem 0 0rem;

  & > * {
    font-size: 1.7rem;
  }
  .wrapper {
    position: relative;
  }
  .menu_logo {
    margin-bottom: 1rem;
    @media (min-width: $media_tablet) {
      margin-bottom: 2rem;
    }
  }
  .footer-logo {
    width: 100%;
    margin-bottom: 2rem;
    @media (min-width: $media_tablet) {
      margin-bottom: 4rem;
      padding-right: 1.5rem;
    }
    @media (min-width: $large_desktop) {
      width: 29.7rem;
    }
    p {
      margin-bottom: 2rem;
      @media (min-width: $large_desktop) {
        margin-bottom: 6rem;
      }
    }
    .confidence {
      position: absolute;
      bottom: 0;
      @media (min-width: $media_tablet) {
        position: relative;
      }
      a {
        text-align: left;
        display: block;
        text-decoration: underline;
      }
    }
  }
  nav {
    margin-bottom: 4rem;
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (min-width: $media_tablet) {
      margin-bottom: 0;
    }
    & > * {
      padding-right: 1.5rem;
      &:last-child {
        padding-right: 0;
      }
    }
  }
  a {
    color: #868078;
    transition: color 0.3s ease;
    &:hover {
      color: #fff;
    }
  }
  .footer-item {
    width: 100%;
    margin-bottom: 2rem;
    @media (min-width: $media_tablet) {
      width: auto;
      margin-bottom: 4rem;
    }
    .footer-link-head {
      text-align: left;
      display: block;
      color: #fff;
      font-size: 1.7rem;
      margin-bottom: 1.2rem;
      @media (min-width: $large_desktop) {
        margin-bottom: 4.4rem;
      }
    }
    ul {
      li {
        max-width: 100%;
        font-size: 1.7rem;
        @media (min-width: $large_desktop) {
          margin-bottom: 1.5rem;
        }
        @media (min-width: $large_desktop) {
          max-width: 29.7rem;
        }
      }
      .footer-link-item {
        font-size: 1.7rem;
      }
    }
    &:last-child {
      ul {
        margin-bottom: 2rem;
        @media (min-width: $media_tablet) {
          margin-bottom: 4rem;
        }
      }
    }
  }
  .footer-social {
    padding-bottom: 5rem;
    @media (min-width: $media_tablet) {
      padding-bottom: 0;
    }
    span {
      margin-bottom: 2rem;
      @media (min-width: $large_desktop) {
        margin-bottom: 3rem;
      }
    }
  }
  .social-container {
    display: flex;
    justify-content: space-between;
    max-width: 16rem;
    margin-top: 1.5rem;
    a {
      width: 3.3rem;
      height: 3.3rem;
      margin-right: 0;
    }
  }
}
.underground {
  background-color: #090706;
  padding: 4rem 1rem;
  p {
    margin-bottom: 0;
    color: #71665b;
    text-align: center;
    font-size: 1.6rem;
  }
}
