.logistic {
  padding: 4.5rem 0;
  @media (min-width: $media_tablet) {
    padding: 9rem 0 5rem;
  }
  .customSplide {
    padding: 3rem 0 4.5rem;
    @media (min-width: $media_tablet) {
      padding: 9rem 0 2.5rem;
    }
    &.p0 {
      padding: 0;
    }
  }
  .column {
    &:first-child {
      display: none;
    }
    @media (min-width: $media_tablet) {
      &:first-child {
        display: block;
      }
      &:last-child {
        padding-left: 4%;
      }
    }
  }
  h2 {
    margin-bottom: 3.5rem;
    @media (min-width: $media_tablet) {
      margin-bottom: 7rem;
    }
  }
  .splide__slide {
    border: none;
    background-color: #161210;
  }
  .splide__head-text {
    color: #fff;
  }
  .splide__main-text {
    margin-bottom: 3rem;
    @media (min-width: $media_tablet) {
      margin-bottom: 5rem;
    }
  }
  .logistic__ico {
    width: 4.8rem;
    height: 4.8rem;
    margin-bottom: 2.5rem;
    @media (min-width: $media_tablet) {
      width: 6rem;
      height: 6rem;
      margin-bottom: 3.5rem;
    }
    svg {
      width: 100%;
      height: 100%;
    }
  }
}
.pageService {
  .logistic {
    .splide__head-text {
      font-size: 1.4rem;
      @media (min-width: $large_desktop) {
        font-size: 2.4rem;
      }
    }
  }
}
