.review {
  padding: 3rem 0;
  background: linear-gradient(to bottom, #f69e29 400px, #ededed 400px);
  @media (min-width: $media_tablet) {
    background: linear-gradient(to bottom, #f69e29 450px, #ededed 450px);
    padding: 6rem 0 8rem;
  }
  h2 {
    text-align: left;
    margin-bottom: 3rem;
    @media (min-width: $media_tablet) {
      text-align: center;
      margin-bottom: 6rem;
    }
  }
  .splide__main-text {
    margin-bottom: 1rem;
    @media (min-width: $media_tablet) {
      margin-bottom: 5rem;
    }
  }
  .splide__main-text {
    margin-bottom: 0;
  }
  .persone-img {
    width: 8.8rem;
    height: 8.8rem;

    margin-bottom: 2rem;
    @media (min-width: $media_tablet) {
      margin-bottom: 0;
      margin-right: 1rem;
    }
  }
  .splide__content {
    padding: 4rem 3rem;
  }
  .persone-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 2rem;
    @media (min-width: $media_tablet) {
      margin-bottom: 4rem;
      flex-direction: row;
      align-items: center;
    }
  }
  .persone-name {
    max-width: 18.9rem;
    font-size: 1.8rem;
    color: #402706;
    @media (min-width: $media_tablet) {
      font-size: 2.4rem;
    }
  }
}
