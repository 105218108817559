.services {
  padding: 4rem 0;
  @media (min-width: $media_tablet) {
    padding: 8rem 0;
  }
  .columns-container {
    align-items: flex-start;
  }
  h2 {
    margin-bottom: 3rem;
    @media (min-width: $large_desktop) {
      margin-bottom: 8rem;
    }
  }
  .media-container {
    width: 100%;

    margin-bottom: 4.5rem;
    @media (min-width: $large_desktop) {
      margin-bottom: 9rem;
    }
  }
  .column {
    &:first-child {
      margin-bottom: 3rem;
      & > * {
        max-width: 544px;
      }
    }
  }
  &__header {
    color: #fff;
    margin-bottom: 6rem;
    font-size: 1.6rem;
    font-weight: 300;
    @media (min-width: $large_desktop) {
      font-size: 2.4rem;
    }
  }
  &__list-item {
    color: #fff;
    font-size: 1.4rem;
    font-weight: 300;
    margin-bottom: 4rem;
    position: relative;
    padding-left: 5rem;
    @media (min-width: $large_desktop) {
      font-size: 1.8rem;
      margin-bottom: 6rem;
    }
    &:before {
      content: '';
      display: inline-block;
      margin-right: 4rem;
      width: 1.8rem;
      height: 1.2rem;
      left: 0;
      top: 0;
      border-left: 6px solid #e49f46;
      border-bottom: 6px solid #e49f46;
      transform: rotate(-45deg);
      position: absolute;
    }
  }
}
