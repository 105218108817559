.feature {
  background-color: #27201c;
  padding: 2rem 0;
  @media (min-width: $media_tablet) {
    padding: 3rem 0 6rem;
  }
  h2 {
    color: #f69e29;
  }
  .feature-item {
    background-color: #161210;
    padding: 4rem 2rem;
    margin-bottom: 2.4rem;
    @media (min-width: $media_tablet) {
      padding: 4rem;
      min-height: 30rem;
    }
  }
  .column-grid {
    margin-bottom: 2.4rem;
    @media (min-width: $media_tablet) {
      display: flex;
      align-items: center;
      min-height: 30rem;
    }
  }
  .feature-header {
    color: #fff;
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1.4rem;
    @media (min-width: $large_desktop) {
      font-size: 2.4rem;
      margin-bottom: 2rem;
    }
  }
}
