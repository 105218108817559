.benefits {
  background-color: #161210;
  padding: 5rem 0;
  @media (min-width: $media_tablet) {
    padding: 7rem 0;
  }
  h2 {
    color: #fff;
    margin-bottom: 5rem;
    @media (min-width: $large_desktop) {
      margin-bottom: 7rem;
    }
  }
  &__list {
    @media (min-width: $media_tablet) {
      display: grid;
      gap: 3%;
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  &__list-item {
    @media (min-width: $media_tablet) {
      display: grid;
      grid-template-columns: auto;
      grid-template-rows: minmax(6rem, auto) 1fr;
      position: relative;
      &::after {
        content: '';
        display: block;
        position: absolute;
        width: 1px;
        height: 100%;
        right: 0;
        background-color: #27201c;
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
    }
    @media (min-width: $large_desktop) {
      grid-template-rows: minmax(8rem, auto) 1fr;
    }
  }
  &__header {
    color: #f69e29;
    font-weight: 600;
    font-size: 1.8rem;
    margin-bottom: 1rem;
    padding-right: 2rem;
    @media (min-width: $large_desktop) {
      font-size: 2.8rem;
      margin-bottom: 0;
    }
  }
  &__text {
    margin-bottom: 5rem;
    @media (min-width: $media_tablet) {
      margin-bottom: 2rem;
      padding-right: 2rem;
      max-width: 35rem;
    }
  }
}
