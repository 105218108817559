@charset "UTF-8";
/* variables */
.transitions {
  transition: all 0.4s ease;
}

.wrapper,
.wide-wrapper,
.middle-wrapper {
  width: 100%;
  margin: 0 auto;
}

.flex-wrap {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;
}

.wrapper {
  max-width: 1360px;
  padding: 0 1rem;
  min-width: 320px;
  box-sizing: border-box;
}

.wide-wrapper {
  max-width: 1680px;
  position: relative;
}

.middle-wrapper {
  position: relative;
  max-width: 1440px;
  padding: 0 1rem;
  box-sizing: border-box;
}

.svg-ico {
  fill: #f69e29;
}
.svg-ico:hover {
  fill: #868078;
}

.preventScroll {
  overflow: hidden;
}

.btn {
  cursor: pointer;
  font-size: 1.4rem;
  background: transparent;
  line-height: 2rem;
  vertical-align: middle;
  color: #fff;
  text-align: center;
  border: 1px solid #ff9e1b;
  text-decoration: none;
  position: relative;
  font-family: "Roboto", sans-serif;
  margin: 0;
  display: inline-block;
  padding: 0.7rem 1rem;
  transition: all 0.3s ease;
}
@media (min-width: 1200px) {
  .btn {
    font-size: 1.6rem;
  }
}
.btn:hover {
  background-color: #ff9e1b;
  color: #0b0b0b;
}
.btn__transparent, .btn__orange {
  margin: 0 auto;
  display: block;
  font-weight: 600;
  padding: 1.5rem 2rem;
  max-width: 20rem;
}
.btn__orange {
  background-color: #ff9e1b;
  color: #0b0b0b;
}
.btn__orange:hover {
  background-color: #ff9e1b;
  color: #fff;
}
.btn__transparent {
  background-color: transparent;
}

.bg-img {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  object-fit: cover;
  object-position: center bottom;
}

.fz-24 {
  font-size: 1.6rem;
}
@media (min-width: 768px) {
  .fz-24 {
    font-size: 2.4rem;
  }
}

.form__btn,
.form__input {
  border: none;
  text-transform: uppercase;
  font-weight: 600;
  width: 100%;
  letter-spacing: 0.1rem;
  line-height: 2.4rem;
}

.form__btn {
  color: #0b0b0b;
  background-color: #f69e29;
  height: 8rem;
  margin-bottom: 0.8rem;
  cursor: pointer;
  font-size: 1.6rem;
  transition: color 0.3s ease;
}
@media (min-width: 1200px) {
  .form__btn {
    font-size: 1.8rem;
  }
}
.form__btn:hover {
  color: #fff;
}

.form__input {
  width: 100%;
  margin-bottom: 0.8rem;
  position: relative;
}
.form__input label {
  color: #c0c0c0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  padding: 2.5rem 1.5rem;
  font-size: 1.4rem;
  font-family: "Roboto", sans-serif;
}
@media (min-width: 768px) {
  .form__input label {
    padding: 2.5rem 0.5rem;
  }
}
@media (min-width: 1200px) {
  .form__input label {
    font-size: 1.8rem;
    padding: 2.5rem 1.5rem;
  }
}
.form__input label .input_icon {
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 0.8rem;
  display: inline-block;
}
.form__input input {
  font-family: "Roboto", sans-serif;
  color: #0b0b0b;
  background-color: #fff;
  width: 100%;
  height: 100%;
  border: none;
  font-size: 1.4rem;
  padding: 2.5rem 1.5rem;
}
@media (min-width: 768px) {
  .form__input input {
    padding: 2.5rem 0.5rem;
  }
}
@media (min-width: 1200px) {
  .form__input input {
    font-size: 1.8rem;
    padding: 2.5rem 1.5rem;
  }
}
.form__input input::placeholder {
  color: #c0c0c0;
}
.form__input input:focus ~ label, .form__input input:not(:focus):valid ~ label {
  display: none;
}

.popupForm label {
  color: #868078;
}
.popupForm label,
.popupForm input {
  font-size: 1.8rem;
  padding: 1.7rem 3.3rem;
  text-transform: none;
  font-weight: 300;
}
.popupForm textarea {
  font-size: 1.8rem;
  font-family: "Roboto", sans-serif;
  padding: 1.7rem 2.3rem;
  outline: none;
  width: 100%;
  border: none;
  height: 100%;
  min-height: 11.2rem;
  vertical-align: top;
  resize: none;
  font-weight: 300;
}
.popupForm textarea ~ label {
  font-weight: 300;
  height: auto;
}
.popupForm textarea:focus ~ label, .popupForm textarea:not(:focus):valid ~ label {
  display: none;
}
.popupForm .form__input {
  margin-bottom: 1.6rem;
}
@media (min-width: 768px) {
  .popupForm .form__input {
    margin-bottom: 2rem;
  }
}
.popupForm .popupColuns {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.popupForm .popupColuns .popupColunmItem:first-child, .popupForm .popupColuns .popupColunmItem:last-child {
  width: 100%;
}
@media (min-width: 768px) {
  .popupForm .popupColuns .popupColunmItem:first-child, .popupForm .popupColuns .popupColunmItem:last-child {
    width: 48%;
  }
}
.popupForm .form__btn {
  height: 5.6rem;
  font-weight: 600;
}
.popupForm input[type=file] {
  opacity: 0;
  min-height: 11.2rem;
}
.popupForm input[type=file] ~ label {
  background-color: transparent;
  cursor: pointer;
  border: 2px dashed #868078;
  display: flex;
  justify-content: center;
}
.popupForm input[type=file] ~ label svg {
  flex-shrink: 0;
  margin-left: 1rem;
  width: 1.7rem;
  height: 1.6rem;
  display: inline-block;
}

.input-checkbox {
  position: relative;
  line-height: 1.3;
}
.input-checkbox input {
  visibility: hidden;
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 50%;
  margin: 0;
}
.input-checkbox input:checked + label:after {
  display: block;
  border-color: #868078;
}
.input-checkbox label {
  font-size: 1.4rem;
  color: #868078;
  line-height: 1.2;
  display: block;
  padding-left: 3.4rem;
}
.input-checkbox label a {
  color: #868078;
  text-decoration: underline;
}
.input-checkbox label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 2rem;
  height: 2rem;
  border-radius: 0.4rem;
  z-index: 2;
  border: 1px solid #d1d5db;
  box-sizing: border-box;
  background: #fff;
  cursor: pointer;
}
.input-checkbox label:after {
  content: "";
  position: absolute;
  left: 0.6rem;
  top: 50%;
  width: 0.6rem;
  height: 1.1rem;
  border: solid transparent;
  border-width: 0 2px 2px 0;
  transform: translateY(-60%) rotate(45deg);
  display: block;
  z-index: 5;
  background: transparent;
}

.columns-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.columns-container .column {
  width: 100%;
}
@media (min-width: 768px) {
  .columns-container .column {
    width: 49%;
  }
}

.operator {
  display: none;
  justify-content: flex-start;
  align-items: center;
}
.operator svg {
  width: 3.7rem;
  height: 3.9rem;
  margin-right: 2rem;
}
.operator p {
  font-size: 1.4rem;
}
@media (min-width: 768px) {
  .operator {
    display: flex;
  }
}

.my-carousel-progress {
  margin-top: 0.8rem;
  background: transparent;
  display: block;
}
@media (min-width: 992px) {
  .my-carousel-progress {
    display: none;
  }
}

.my-carousel-progress-bar {
  background: #d9d9d9;
  height: 3px;
  transition: width 400ms ease;
  width: 0;
}

.dark-section {
  background-color: #27201c;
}
.dark-section h2 {
  color: #f69e29;
}

.image-decor-border {
  position: relative;
}
.image-decor-border picture,
.image-decor-border img {
  position: relative;
  z-index: 2;
  width: calc(100% - 0.7rem);
  height: calc(100% - 0.7rem);
}
@media (min-width: 768px) {
  .image-decor-border picture,
  .image-decor-border img {
    width: calc(100% - 1.5rem);
    height: calc(100% - 1.5rem);
  }
}
.image-decor-border::after {
  content: "";
  display: block;
  position: absolute;
  width: calc(100% - 0.7rem);
  height: 100%;
  background-color: #f69e29;
  top: -0.7rem;
  right: 0;
  z-index: 0;
}
@media (min-width: 768px) {
  .image-decor-border::after {
    width: calc(100% - 1.5rem);
    top: -1.5rem;
  }
}

.customSplide.splide.is-initialized:not(.is-active) .grid-2-4-3-6, .customSplide.splide.is-initialized:not(.is-active) .grid-4-8-12 {
  display: flex;
}

.customSplide .splide__slide {
  background-color: #fff;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.04));
  border: 1px solid #eaeaea;
  position: relative;
  display: flex;
  flex-direction: column;
}
.customSplide .splide__img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.customSplide .splide__content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1.5rem;
}
@media (min-width: 992px) {
  .customSplide .splide__content {
    padding: 3.5rem 2.5rem 2.5rem;
  }
}
.customSplide .splide__head-text {
  font-size: 2.4rem;
  color: #100f0f;
  margin-bottom: 1rem;
}
@media (min-width: 992px) {
  .customSplide .splide__head-text {
    margin-bottom: 2.6rem;
  }
}
.customSplide .splide__main-text {
  flex-grow: 1;
  margin-bottom: 2rem;
}
.customSplide .splide__link {
  color: #f69e29;
  font-size: 1.8rem;
  font-weight: 500;
  position: relative;
  overflow: hidden;
}
.customSplide .splide__link::after {
  content: "";
  transition: left 0.3s ease;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #f69e29;
  bottom: 0;
  left: -100%;
}
.customSplide .splide__link:hover::after {
  left: 0;
}

.desctopSplide {
  margin-bottom: 4rem;
}
.desctopSplide .splide__slide {
  background-color: #161210;
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 25rem;
}
@media (min-width: 768px) {
  .desctopSplide .splide__slide {
    max-width: 32rem;
  }
}
.desctopSplide .splide__img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.desctopSplide .splide__content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1.5rem;
}
@media (min-width: 768px) {
  .desctopSplide .splide__content {
    padding: 2.5rem 3rem 2.5rem;
  }
}
.desctopSplide .splide__date {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}
.desctopSplide .splide__date span {
  display: none;
}
@media (min-width: 768px) {
  .desctopSplide .splide__date span {
    display: inline-block;
  }
}
.desctopSplide .splide__head-text {
  font-size: 1.7rem;
  color: #ffffff;
  font-weight: 400;
  margin-bottom: 2.6rem;
}
.desctopSplide .splide__main-text {
  margin-bottom: 4rem;
  flex-grow: 1;
  font-size: 1.4rem;
}
@media (min-width: 768px) {
  .desctopSplide .splide__main-text {
    font-size: 1.7rem;
  }
}
.desctopSplide .splide__link {
  color: #f69e29;
  font-size: 1.7rem;
  font-weight: 500;
  position: relative;
  overflow: hidden;
}
.desctopSplide .splide__link::after {
  content: "";
  transition: left 0.3s ease;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #f69e29;
  bottom: 0;
  left: -100%;
}
.desctopSplide .splide__link:hover::after {
  left: 0;
}
.desctopSplide .my-carousel-progress {
  display: block;
  margin-top: 2.3rem;
}
@media (min-width: 1500px) {
  .desctopSplide .my-carousel-progress {
    display: none;
  }
}
.desctopSplide .splide__arrows {
  display: none;
}
@media (min-width: 1500px) {
  .desctopSplide .splide__arrows {
    display: block;
  }
}
.desctopSplide .splide__arrow {
  background-color: transparent;
}
.desctopSplide .splide__arrow svg {
  fill: #e49f46;
}
.desctopSplide .splide__arrow--prev {
  left: -5rem;
}
.desctopSplide .splide__arrow--next {
  right: -5rem;
}

.grid-2-4-3-6 {
  display: flex;
}
@media (min-width: 992px) {
  .grid-2-4-3-6 {
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.grid-2-4-3-6 .splide__slide {
  width: 100%;
  max-width: 260px;
}
@media (min-width: 500px) {
  .grid-2-4-3-6 .splide__slide {
    max-width: 460px;
  }
}
@media (min-width: 992px) {
  .grid-2-4-3-6 .splide__slide {
    max-width: 100%;
    margin-bottom: 2%;
    width: 32%;
  }
  .grid-2-4-3-6 .splide__slide:nth-last-child(2):first-child, .grid-2-4-3-6 .splide__slide:nth-last-child(2):first-child ~ li, .grid-2-4-3-6 .splide__slide:nth-last-child(4):first-child, .grid-2-4-3-6 .splide__slide:nth-last-child(4):first-child ~ li {
    width: 49%;
  }
}

.grid-4-8-12 {
  display: flex;
}
@media (min-width: 992px) {
  .grid-4-8-12 {
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.grid-4-8-12 .splide__slide {
  width: 100%;
  max-width: 260px;
}
@media (min-width: 500px) {
  .grid-4-8-12 .splide__slide {
    max-width: 315px;
  }
}
@media (min-width: 992px) {
  .grid-4-8-12 .splide__slide {
    max-width: 100%;
    margin-bottom: 1.5%;
    width: 24%;
  }
}

#popup-call .modal__container {
  background-color: #1d1815;
  width: 100%;
  height: auto;
  padding: 2.5rem;
}
#popup-call .popup-call-list li {
  padding: 1.5rem 0;
  border-bottom: 1px solid #322a26;
}
#popup-call .popup-call-list span {
  display: block;
  margin-bottom: 0.5rem;
}
#popup-call .phone_city {
  color: #f69e29;
  font-size: 1.6rem;
}
#popup-call .phone_schedule {
  color: #868078;
  font-size: 1.3rem;
}
#popup-call .phone_mail {
  color: #868078;
  padding: 1.5rem 1rem;
  display: block;
}
#popup-call .btn {
  margin-top: 3rem;
  width: 100%;
  font-size: 1.4rem;
  height: 4rem;
}
#popup-call .phone_num {
  color: #fff;
}

.tac {
  text-align: left;
}
@media (min-width: 768px) {
  .tac {
    text-align: center;
  }
}

.wrapper-3 {
  display: grid;
  grid-template-columns: 1fr;
}
@media (min-width: 768px) {
  .wrapper-3 {
    grid-template-columns: repeat(3, 1fr);
    gap: 2%;
  }
}
.wrapper-3 .card {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  .wrapper-3 .card {
    margin-bottom: 0;
  }
}

.wrapper-4 {
  display: grid;
  grid-template-columns: 1fr;
}
@media (min-width: 768px) {
  .wrapper-4 {
    grid-template-columns: repeat(3, 1fr);
    gap: 2%;
  }
}
@media (min-width: 992px) {
  .wrapper-4 {
    grid-template-columns: repeat(4, 1fr);
  }
}
.wrapper-4 .card {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  .wrapper-4 .card {
    margin-bottom: 0;
  }
}

body {
  -webkit-text-size-adjust: 100%;
}

input[type=submit],
input[type=text],
input[type=password],
input[type=file],
textarea {
  -webkit-appearance: none;
}

textarea,
input[type=submit],
input[type=text] {
  -webkit-border-radius: 0;
}

input[type=image] {
  padding: 0;
  border: none;
}

img {
  vertical-align: top;
  border: none;
}

form,
fieldset {
  margin: 0;
  padding: 0;
  border-style: none;
}

input,
textarea,
select {
  vertical-align: middle;
  color: #000;
}

label {
  vertical-align: middle;
}

input:focus {
  outline: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

figure {
  margin: 1em 40px;
}

audio {
  display: inline-block;
  vertical-align: baseline;
}
audio:not([controls]) {
  display: none;
  height: 0;
}

canvas {
  display: inline-block;
  vertical-align: baseline;
}

progress {
  display: inline-block;
  vertical-align: baseline;
}

video {
  display: inline-block;
  vertical-align: baseline;
}

[hidden] {
  display: none;
}

template {
  display: none;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b {
  font-weight: bold;
}

strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

img {
  border: 0;
  border: none;
  max-width: 100%;
}

hr {
  box-sizing: content-box;
  height: 0;
}

small {
  font-size: 80%;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

pre {
  overflow: auto;
  font-size: 1em;
}

code {
  font-size: 1em;
}

kbd {
  font-size: 1em;
}

samp {
  font-size: 1em;
}

table,
th,
td {
  border-spacing: 0px;
  border-collapse: collapse;
}

html {
  font-size: 62.5%;
}
html input[type=button] {
  cursor: pointer;
}
html input[disabled] {
  cursor: default;
}

body * {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  font-family: "Roboto", sans-serif;
  font-size: 1.6rem;
  line-height: 1.25;
  margin: 0;
  color: #868078;
  min-width: 320px;
  background: #27201c;
}

main {
  background-color: #fff;
}

a {
  color: #ffffff;
  text-decoration: none;
  font-size: 1.5rem;
  text-align: center;
}
a:active {
  outline: 0;
}
a:hover {
  outline: 0;
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

h1,
h2,
h3 {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #34303d;
  line-height: 1.25;
  text-align: left;
  text-transform: none;
  margin: 0 0 1.5rem;
}

h1 {
  font-size: 2.8rem;
  margin: 0 0 1.5rem;
  letter-spacing: -0.1rem;
}
@media (min-width: 992px) {
  h1 {
    letter-spacing: -0.2rem;
    font-size: 4.2rem;
  }
}
@media (min-width: 1200px) {
  h1 {
    font-size: 7.2rem;
  }
}

h2 {
  font-size: 3.2rem;
  margin: 0 0 1rem;
}
@media (min-width: 1200px) {
  h2 {
    margin: 0 0 3rem;
    letter-spacing: -0.2rem;
    font-size: 4.8rem;
  }
}

h3 {
  font-size: 2.4rem;
  font-weight: 100;
  line-height: 1.15;
}
@media (min-width: 1200px) {
  h3 {
    margin: 0 0 3rem;
    font-size: 3.2rem;
    line-height: 1.25;
  }
}

p {
  margin: 0 0 0.5rem;
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1.5;
}
@media (min-width: 1200px) {
  p {
    font-size: 1.7rem;
  }
}

.strong {
  font-weight: bold;
}

svg {
  display: block;
}

img {
  display: block;
  width: 100%;
  height: 100%;
}

sup {
  vertical-align: top;
  position: relative;
  top: -0.5em;
  font-size: 0.5em;
}

header.relative-header {
  position: relative;
  background-color: #27201c;
  height: 70px;
}
@media (min-width: 768px) {
  header.relative-header {
    height: 90px;
  }
}
@media (min-width: 1200px) {
  header.relative-header {
    height: 130px;
  }
}
header .header-wrapper {
  transition: padding 0.4s ease;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
}
@media (min-width: 768px) {
  header .header-wrapper {
    padding: 1rem 0;
  }
}
@media (min-width: 1200px) {
  header .header-wrapper {
    padding: 3rem 0;
  }
}
header .header-wrapper._fixed {
  animation-name: menuAnimate;
  animation-duration: 1s;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 20;
  padding: 0;
  background-color: #27201c;
}
@media (max-width: 767px) {
  header .header-wrapper._fixed .menu_body {
    padding: 7rem 1rem 3rem;
  }
  header .header-wrapper._fixed .menu_body::before {
    height: 7rem;
  }
}
header .wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 7rem;
}

.menu-left {
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 1;
}
@media (min-width: 768px) {
  .menu-left {
    padding-right: 1rem;
    flex-grow: 0;
  }
}
.menu-left .social-container {
  display: none;
}
@media (min-width: 768px) {
  .menu-left .social-container {
    display: block;
  }
}
@media (max-width: 1199px) {
  .menu-left .social-container a {
    width: 1.6rem;
    height: 1.6rem;
  }
  .menu-left .social-container a:first-child {
    width: 1.4rem;
    height: 1.4rem;
    margin-right: 0.3rem;
  }
}
@media (max-width: 767px) {
  .menu-left .menu_sub-list {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .menu-left .menu_sub-list::after, .menu-left .menu_sub-list::before {
    content: "";
    top: 2rem;
    right: 1rem;
    display: block;
    position: absolute;
    border: none;
    width: 2rem;
    height: 2px;
    background-color: #6c6262;
  }
  .menu-left .menu_sub-list::before {
    transform: rotate(45deg);
  }
  .menu-left .menu_sub-list::after {
    transform: rotate(135deg);
  }
}

.ico-phone {
  background-color: transparent;
  border: none;
  padding: 0;
  z-index: 5;
  display: block;
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 2rem;
}
.ico-phone svg {
  width: 100%;
  height: 100%;
}
@media (min-width: 768px) {
  .ico-phone {
    display: none;
  }
}

.social-container {
  max-width: 10rem;
}
.social-container a {
  margin-right: 0.5rem;
  display: inline-block;
  vertical-align: middle;
  width: 2.1rem;
  height: 2.1rem;
}
.social-container a svg {
  width: 100%;
  height: 100%;
}
.social-container a:last-child {
  margin-right: 0;
}
.social-container a:first-child {
  width: 1.9rem;
  height: 1.9rem;
  margin-right: 0.7rem;
}

.menu_logo {
  width: 100%;
  height: auto;
  max-width: 16.4rem;
  max-height: 4.1rem;
  display: block;
  padding-right: 2rem;
}
@media (min-width: 1200px) {
  .menu_logo {
    max-width: 24.4rem;
    max-height: 6rem;
    padding-right: 6rem;
  }
}
.menu_logo a,
.menu_logo img {
  width: 100%;
  height: auto;
}

.menu_phone {
  color: #fff;
  position: relative;
  margin-right: 2rem;
}
@media (min-width: 768px) {
  .menu_phone {
    font-size: 1.4rem;
    padding: 1rem 0;
  }
}
@media (min-width: 1200px) {
  .menu_phone {
    font-size: 1.6rem;
    margin-right: 4rem;
  }
}
.menu_phone > span {
  cursor: pointer;
  display: none;
}
@media (min-width: 768px) {
  .menu_phone > span {
    display: inline-block;
  }
}
.menu_phone .menu_sub-list {
  padding: 1rem 1rem 2.5rem 1rem;
  display: none;
}
@media (min-width: 768px) {
  .menu_phone .menu_sub-list {
    display: block;
  }
}
.menu_phone .menu_sub-list li {
  padding: 1.5rem;
  border-bottom: 1px solid #322a26;
}
.menu_phone .menu_sub-list span {
  display: block;
  margin-bottom: 0.5rem;
}
.menu_phone .phone_city {
  color: #f69e29;
  font-size: 1.6rem;
}
.menu_phone .phone_schedule {
  color: #868078;
  font-size: 1.3rem;
}
.menu_phone .phone_mail {
  color: #868078;
  padding: 1.5rem 1rem;
  display: block;
}
.menu_phone .btn {
  width: 100%;
  font-size: 1.4rem;
  height: 4rem;
}

.menu_body {
  z-index: 5;
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .menu_body {
    display: block;
    position: fixed;
    left: 0;
    top: -100%;
    width: 100%;
    height: 100%;
    background-color: #1d1815;
    transition: top 0.4s ease;
    padding: 7rem 1rem 3rem;
    overflow: auto;
  }
  .menu_body._bg:before {
    content: "";
    transition: all 0.3s ease;
    transition-delay: 1s;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 7rem;
    background-color: #1d1815;
    z-index: 20;
  }
  .menu_body._active {
    top: 0%;
  }
}
@media (min-width: 768px) {
  .menu_body:before {
    height: 12rem;
  }
}
.menu_body > .btn {
  display: none;
}
@media (min-width: 768px) {
  .menu_body > .btn {
    display: block;
  }
}

.menu_list {
  margin-bottom: 4rem;
}
@media (min-width: 768px) {
  .menu_list {
    margin-bottom: 0;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }
}
.menu_list * {
  color: #ffffff;
  font-size: 1.8rem;
  transition: color 0.4s ease;
}
@media (min-width: 768px) {
  .menu_list * {
    font-size: 1.4rem;
  }
}
@media (min-width: 1200px) {
  .menu_list * {
    font-size: 1.6rem;
  }
}
.menu_list > li {
  cursor: pointer;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  border-bottom: 1px solid #2f2824;
}
@media (min-width: 768px) {
  .menu_list > li {
    border-bottom: none;
    margin-right: 1.5rem;
  }
}
.menu_list > li a {
  display: block;
  padding: 2.5rem 0;
  text-align: left;
}
@media (min-width: 768px) {
  .menu_list > li a {
    padding: 1rem 0;
  }
}

.menu_sub-list {
  z-index: 15;
  background-color: #1d1815;
  position: absolute;
  top: 100%;
  min-width: 30rem;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: all 0.3s ease 0s;
  transform: translate(0px, 10%);
}
.menu_sub-list > li {
  padding: 0;
  border-bottom: 1px solid #322a26;
}
.menu_sub-list > li:last-child {
  border-bottom: none;
}
@media (min-width: 768px) {
  .menu_sub-list > li a {
    padding: 1.7rem 1rem;
  }
}
@media (min-width: 768px) {
  .menu_sub-list {
    min-width: 14rem;
    right: -2.8rem;
    padding: 0 1rem;
  }
}

.menu-right {
  z-index: 1;
}
@media (max-width: 767px) {
  .menu-right .menu_sub-list {
    background-color: #0e0b0a;
    flex: 1 1 100%;
    display: none;
    position: relative;
  }
}

.menu-burger {
  z-index: 5;
  display: block;
  position: relative;
  width: 1.6rem;
  height: 1.4rem;
  cursor: pointer;
}
.menu-burger span, .menu-burger::before, .menu-burger::after {
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  transition: all 0.4s ease-in-out;
  background-color: #f69e29;
  border-radius: 2px;
}
.menu-burger::before, .menu-burger::after {
  content: "";
}
.menu-burger::before {
  top: 0;
}
.menu-burger::after {
  bottom: 0;
}
.menu-burger span {
  top: 50%;
  transform: scale(1) translate(0, -50%);
}
@media (min-width: 768px) {
  .menu-burger {
    display: none;
  }
}
.menu-burger._active span {
  transform: scale(0) translate(0, -50%);
}
.menu-burger._active::before {
  top: 50%;
  transform: rotate(-45deg) translate(0px, -50%);
}
.menu-burger._active::after {
  bottom: 50%;
  transform: rotate(45deg) translate(0px, 50%);
}

.mobile-menu-info {
  display: block;
}
@media (min-width: 768px) {
  .mobile-menu-info {
    display: none;
  }
}
.mobile-menu-info > p,
.mobile-menu-info > a {
  color: #868078;
  font-size: 1.6rem;
  margin-bottom: 1rem;
  display: block;
  text-align: left;
}
.mobile-menu-info .mobile-contact {
  padding-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

._pc .menu_phone:hover .menu_sub-list {
  opacity: 1;
  pointer-events: all;
  visibility: visible;
  transform: translate(-50%, -50%);
}
@media (min-width: 768px) {
  ._pc .menu_phone:hover .menu_sub-list {
    transform: translate(0px, 0px);
  }
}
._pc .menu_phone:hover .main_number {
  transition: all 0.3s ease;
  color: #868078;
}
._pc .menu_phone:hover .menu-arr {
  border-color: #868078;
  transform: rotate(135deg);
}
._pc .menu_list li:hover .menu_sub-list {
  opacity: 1;
  pointer-events: all;
  visibility: visible;
  transform: translate(0px, 0px);
  display: block;
}
._pc .menu_list li:hover > a {
  color: #868078;
}
._pc .menu_list li:hover .menu-arr {
  border-color: #868078;
  transform: rotate(135deg);
}

._touch .menu_phone.activeArr .menu_sub-list {
  opacity: 1;
  pointer-events: all;
  visibility: visible;
  transform: translate(-50%, -50%);
}
@media (min-width: 768px) {
  ._touch .menu_phone.activeArr .menu_sub-list {
    transform: translate(0px, 0px);
  }
}
._touch .menu_phone.activeArr .menu-arr {
  transform: rotate(135deg);
}
._touch .menu_list > .activeArr .menu_sub-list {
  opacity: 1;
  pointer-events: all;
  visibility: visible;
  transform: translate(0px, 0px);
  display: block;
}
._touch .menu_list > .activeArr > .menu-arr {
  transform: rotate(135deg);
}

.menu-arr {
  margin-left: 0.7rem;
  margin-bottom: 0.3rem;
  width: 0.6rem;
  height: 0.6rem;
  display: inline-block;
  vertical-align: middle;
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  transform: rotate(-45deg);
  transition: all 0.4s ease;
}

.decor-popup::before {
  content: "";
  top: -1.5rem;
  right: 1.9rem;
  position: absolute;
  width: 0;
  height: 0;
  border-left: 1.2rem solid transparent;
  border-right: 1.2rem solid transparent;
  border-bottom: 1.5rem solid #1d1815;
  display: none;
}
@media (min-width: 768px) {
  .decor-popup::before {
    display: block;
  }
}

@keyframes menuAnimate {
  0% {
    top: -100%;
  }
  75% {
    top: 0;
  }
}
.main-banner {
  background-color: #fff;
  min-height: auto;
  position: relative;
}
@media (min-width: 992px) {
  .main-banner {
    min-height: 710px;
  }
}
@media (min-width: 1200px) {
  .main-banner {
    min-height: 880px;
  }
}
.main-banner.flex-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.main-banner.flex-banner .banner-content {
  padding-bottom: 0;
}
.main-banner.flex-banner .bg-img {
  height: calc(100% - 5rem);
}
.main-banner.flex-banner.dark {
  background-color: #27201c;
}
.main-banner .bg-img {
  left: 50%;
  transform: translateX(-50%);
  max-width: 2500px;
}
.main-banner .banner-content {
  width: 100%;
  position: relative;
  height: 100%;
  padding-bottom: 3rem;
  flex-grow: 1;
}
.main-banner h1,
.main-banner p {
  color: #fff;
}
.main-banner .title__banner {
  display: inline-block;
  padding: 8rem 0 3rem;
}
@media (min-width: 768px) {
  .main-banner .title__banner {
    padding: 12rem 2rem 3rem 0;
  }
}
@media (min-width: 992px) {
  .main-banner .title__banner {
    padding: 18rem 2rem 7rem 0;
  }
}
@media (min-width: 1200px) {
  .main-banner .title__banner {
    padding: 21rem 2rem 17rem 0;
  }
}
.main-banner .title__banner.tiny-title {
  padding: 0 2rem 0 0;
  max-width: 100rem;
  margin: 8rem 0 0;
}
@media (min-width: 768px) {
  .main-banner .title__banner.tiny-title {
    margin: 10rem 2rem 3rem 0;
  }
}
@media (min-width: 992px) {
  .main-banner .title__banner.tiny-title {
    margin: 10rem 2rem 7rem 0;
  }
}
@media (min-width: 1200px) {
  .main-banner .title__banner.tiny-title {
    margin: 17rem 2rem 0 0;
  }
}
.main-banner p {
  max-width: 70rem;
}

.banner-breadcrump {
  margin-bottom: 3rem;
}
@media (min-width: 1200px) {
  .banner-breadcrump {
    margin-bottom: 4rem;
  }
}
.banner-breadcrump ul {
  display: flex;
  flex-wrap: wrap;
}
.banner-breadcrump li {
  font-size: 1.3rem;
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .banner-breadcrump li {
    font-size: 1.6rem;
  }
}
.banner-breadcrump li:after {
  content: "›";
  color: #f69e29;
  display: inline-block;
  margin: 0 0.9rem;
}
@media (min-width: 768px) {
  .banner-breadcrump li:after {
    margin: 0 2rem;
  }
}
.banner-breadcrump li:last-child::after {
  display: none;
}

.banner-form {
  display: none;
  background-color: #fff;
  max-width: 34rem;
  width: 100%;
}
@media (min-width: 768px) {
  .banner-form {
    margin-top: 10rem;
    display: inline-block;
    padding: 1.5rem;
  }
}
@media (min-width: 1200px) {
  .banner-form {
    margin-top: 17rem;
    padding: 3rem;
  }
}
.banner-form .form__input {
  margin-bottom: 1rem;
}
.banner-form .form__input input,
.banner-form .form__input label,
.banner-form .form__input textarea {
  padding: 1.7rem;
  background-color: #ececec;
  color: #9a9a9a;
  font-weight: bold;
  font-size: 1.4rem;
}
.banner-form .form__btn {
  font-size: 1.4rem;
}
.banner-form .form__input label {
  text-transform: uppercase;
}
.banner-form .modal__header {
  margin-bottom: 1rem;
}
.banner-form .modal__text {
  color: #868078;
  font-size: 1.4rem;
  margin-bottom: 1.5rem;
}

.tabs {
  width: 100%;
}
.tabs__navigation {
  display: flex;
  flex-wrap: wrap;
  max-width: 800px;
}
.tabs__btn {
  position: relative;
  flex: 0 0 48%;
  padding: 1rem 0.5rem;
  border: none;
  background-color: transparent;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: #9b9b9b;
  transition: color 0.2s;
  cursor: pointer;
  background-color: #1d1815;
  margin-bottom: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.tabs__btn:nth-of-type(odd) {
  margin-right: 0.8rem;
}
@media (min-width: 992px) {
  .tabs__btn {
    justify-content: center;
    padding: 1rem 2.5rem;
    flex: 1 0 auto;
    margin: 0;
    font-size: 1.8rem;
  }
  .tabs__btn:nth-of-type(odd) {
    margin-right: 0;
  }
}
.tabs__btn.active {
  transition: all 0.3s ease;
  color: #0b0b0b;
  background-color: #f69e29;
}
.tabs__btn.active .tabs__ico {
  stroke: #0b0b0b;
}
.tabs__ico {
  margin-right: 1rem;
  width: 2.4rem;
  height: 2.4rem;
  display: inline-block;
  stroke: #9b9b9b;
  fill: none;
  stroke-width: 2;
  stroke-linecap: round;
}
.tabs__content {
  margin-top: 3rem;
}
.tabs__body {
  width: 100%;
  display: none;
  font-size: 18px;
}
.tabs__body.active {
  display: block;
}
.tabs__body.last_body .form__btn {
  margin-top: 0;
}
@media (min-width: 992px) {
  .tabs__body.last_body {
    max-width: 97rem;
  }
  .tabs__body.last_body .form__btn {
    max-width: 32rem;
  }
}
.tabs__form {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.tabs__form > * {
  flex-shrink: 1;
  width: 100%;
}
@media (min-width: 992px) {
  .tabs__form {
    flex-direction: row;
  }
}
@media (min-width: 992px) {
  .tabs__form .form__input {
    margin-right: 0.8rem;
  }
}
@media (min-width: 1200px) {
  .tabs__form .form__input {
    margin-right: 2.4rem;
  }
}
.tabs__form button {
  margin-top: 1.5rem;
}
@media (min-width: 992px) {
  .tabs__form button {
    margin-top: 0;
  }
}

.input__flex {
  display: flex;
}
.input__flex .form__input {
  width: 50%;
}
.input__flex .form__input:first-child {
  margin-right: 0.8rem;
}
@media (min-width: 1200px) {
  .input__flex .form__input:first-child {
    margin-right: 2.4rem;
  }
}

.banner-benefits {
  background-color: #f69e29;
  padding: 0.8rem 1.8rem;
  position: relative;
  z-index: 0;
  margin: 0 1rem;
}
.banner-benefits.benefits-white {
  background-color: #fff;
}
.banner-benefits.benefits-white ul li {
  border-color: #e6e6e6;
}
.banner-benefits.benefits-white .svg-ico {
  fill: #f69e29;
}
@media (min-width: 768px) {
  .banner-benefits {
    max-width: 1340px;
    width: 100%;
    margin: 0 auto;
  }
}
.banner-benefits ul {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .banner-benefits ul {
    flex-direction: row;
  }
}
.banner-benefits li {
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding: 1.2rem 3rem;
  border-bottom: 2px solid #d78820;
}
@media (min-width: 768px) {
  .banner-benefits li {
    border-right: 2px solid #d78820;
    border-bottom: none;
  }
}
@media (min-width: 1200px) {
  .banner-benefits li {
    padding: 1.2rem 8rem;
  }
}
.banner-benefits li:last-child {
  border: none;
}
.banner-benefits p {
  font-weight: normal;
  color: #161210;
  font-size: 1.4rem;
  margin-bottom: 0;
}
@media (min-width: 1200px) {
  .banner-benefits p {
    font-size: 1.8rem;
    max-width: 17rem;
  }
}
.banner-benefits .svg-ico {
  max-width: 3.9rem;
  max-height: 3.9rem;
  margin-right: 3rem;
  fill: #161210;
}
@media (min-width: 1200px) {
  .banner-benefits .svg-ico {
    max-width: 5.4rem;
    max-height: 5.7rem;
  }
}

.banner-statistic {
  background-color: #f69e29;
  width: 100%;
  position: relative;
  z-index: 1;
  margin-top: 3rem;
}
@media (min-width: 768px) {
  .banner-statistic {
    padding: 0.8rem 0;
  }
}
.banner-statistic__wrapper {
  max-width: 1600px;
  margin: 0 auto;
  padding: 1rem;
}
.banner-statistic__list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
@media (min-width: 768px) {
  .banner-statistic__list {
    grid-template-columns: repeat(4, 1fr);
  }
}
.banner-statistic__list-item {
  text-align: center;
  padding: 1rem;
}
.banner-statistic__list-item:nth-child(odd) {
  border-right: 2px solid #d78820;
}
.banner-statistic__list-item:nth-child(1), .banner-statistic__list-item:nth-child(2) {
  border-bottom: 2px solid #d78820;
}
@media (min-width: 768px) {
  .banner-statistic__list-item {
    padding: 3rem 1rem 2rem;
    padding: 0;
    border-bottom: 0;
  }
  .banner-statistic__list-item:nth-child(1), .banner-statistic__list-item:nth-child(2) {
    border-bottom: 0;
  }
  .banner-statistic__list-item:nth-child(2) {
    border-right: 2px solid #d78820;
  }
}
.banner-statistic__list-item span {
  font-size: 3.2rem;
  color: #161210;
}
@media (min-width: 1200px) {
  .banner-statistic__list-item span {
    font-size: 4rem;
  }
}
.banner-statistic__list-item p {
  font-size: 1.3rem;
  color: #161210;
  margin-bottom: 0;
  font-weight: normal;
}
@media (min-width: 1200px) {
  .banner-statistic__list-item p {
    font-size: 1.8rem;
  }
}

.delivery {
  padding: 3rem 0;
  position: relative;
}
@media (min-width: 768px) {
  .delivery {
    padding: 7rem 0 5rem;
    background: linear-gradient(to bottom, #fff 520px, #ededed 520px);
  }
}
.delivery h2 {
  max-width: 75rem;
}
.delivery p {
  max-width: 106rem;
}
@media (min-width: 768px) {
  .delivery .dislaimer-paragraph {
    margin-bottom: 5rem;
  }
}
.delivery .splide {
  padding: 3rem 0;
}
@media (min-width: 768px) {
  .delivery .splide {
    padding: 7rem 0 3rem;
  }
}

.delivery-list {
  background-color: #fff;
  margin: 9rem auto 4rem;
  max-width: 1340px;
}
@media (min-width: 768px) {
  .delivery-list {
    filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.1));
  }
}
.delivery-list ul {
  display: grid;
  grid-template-areas: "item1 item2" "item4 item5" "item3 item6";
}
@media (min-width: 768px) {
  .delivery-list ul {
    grid-template-areas: "item1 item2 item3" "item4 item5 item6";
  }
}
.delivery-list .item1 {
  grid-area: item1;
}
.delivery-list .item2 {
  grid-area: item2;
}
.delivery-list .item3 {
  grid-area: item3;
}
.delivery-list .item4 {
  grid-area: item4;
}
.delivery-list .item5 {
  grid-area: item5;
}
.delivery-list .item6 {
  grid-area: item6;
}
.delivery-list .delivery-list-item {
  padding: 5rem 1rem;
}
.delivery-list .delivery-list-item:nth-child(2n) {
  background-color: #f2f2f2;
}
.delivery-list .delivery-list-item:nth-child(2n+1) {
  background-color: #fff;
}
.delivery-list .delivery-list__ico {
  margin: 0 auto 4rem;
  width: 8rem;
  height: 8rem;
}
@media (min-width: 1200px) {
  .delivery-list .delivery-list__ico {
    width: 9.6rem;
    height: 9.6rem;
  }
}
.delivery-list .delivery-list__head-text {
  color: #402706;
  text-align: center;
  font-size: 1.6rem;
  font-weight: 400;
  margin-bottom: 1.5rem;
}
@media (min-width: 1200px) {
  .delivery-list .delivery-list__head-text {
    font-size: 2.4rem;
    line-height: 1.5;
    margin-bottom: 2rem;
  }
}
.delivery-list .delivery-list__main-text {
  color: #5c5751;
  text-align: center;
  font-size: 1.2rem;
  max-width: 29rem;
  margin: 0 auto;
}
@media (min-width: 1200px) {
  .delivery-list .delivery-list__main-text {
    font-size: 1.8rem;
  }
}

.about {
  padding: 4rem 0;
}
@media (min-width: 768px) {
  .about {
    padding: 8rem 0;
  }
}
.about .columns-container {
  flex-wrap: wrap-reverse;
}
@media (min-width: 768px) {
  .about .columns-container {
    margin-top: -5%;
  }
  .about .columns-container .column:first-child {
    padding-top: 5%;
  }
}
.about .columns-container .column:last-child {
  margin: 4rem auto;
}
@media (min-width: 768px) {
  .about .columns-container .column:last-child {
    margin: auto;
  }
}
.about p {
  margin-bottom: 4rem;
  max-width: 54rem;
}
.about .btn {
  padding: 1.5rem 4rem;
  display: block;
  max-width: 20rem;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .about .btn {
    margin: 0;
    display: inline-block;
  }
}

.choose {
  padding: 4rem 0;
  background-color: #ededed;
}
@media (min-width: 768px) {
  .choose {
    padding: 8rem 0;
  }
}
.choose h2 {
  text-align: left;
  margin-bottom: 3rem;
}
@media (min-width: 768px) {
  .choose h2 {
    text-align: center;
    margin-bottom: 8rem;
  }
}
.choose .splide__slide {
  border: 1px solid #dfdfdf;
  border-bottom: 5px solid #f69e29;
}
.choose .splide__head-text {
  color: #342415;
  font-weight: 400;
  max-width: 30rem;
}
.choose .grid-4-8-12 .splide__head-text {
  font-size: 1.6rem;
}
@media (min-width: 1200px) {
  .choose .grid-4-8-12 .splide__head-text {
    font-size: 2.2rem;
  }
}
.choose .grid-4-8-12 .splide__main-text {
  margin-bottom: 3rem;
}
.choose .grid-4-8-12 .splide__content {
  display: grid;
  grid-template-rows: minmax(11rem, auto) 1fr;
}
@media (min-width: 500px) {
  .choose .grid-4-8-12 .splide__content {
    grid-template-rows: minmax(9rem, auto) 1fr;
  }
}
@media (min-width: 1200px) {
  .choose .grid-4-8-12 .splide__content {
    grid-template-rows: minmax(15rem, auto) 1fr;
  }
}

.pageService .choose h2 {
  text-align: left;
}

.logistic {
  padding: 4.5rem 0;
}
@media (min-width: 768px) {
  .logistic {
    padding: 9rem 0 5rem;
  }
}
.logistic .customSplide {
  padding: 3rem 0 4.5rem;
}
@media (min-width: 768px) {
  .logistic .customSplide {
    padding: 9rem 0 2.5rem;
  }
}
.logistic .customSplide.p0 {
  padding: 0;
}
.logistic .column:first-child {
  display: none;
}
@media (min-width: 768px) {
  .logistic .column:first-child {
    display: block;
  }
  .logistic .column:last-child {
    padding-left: 4%;
  }
}
.logistic h2 {
  margin-bottom: 3.5rem;
}
@media (min-width: 768px) {
  .logistic h2 {
    margin-bottom: 7rem;
  }
}
.logistic .splide__slide {
  border: none;
  background-color: #161210;
}
.logistic .splide__head-text {
  color: #fff;
}
.logistic .splide__main-text {
  margin-bottom: 3rem;
}
@media (min-width: 768px) {
  .logistic .splide__main-text {
    margin-bottom: 5rem;
  }
}
.logistic .logistic__ico {
  width: 4.8rem;
  height: 4.8rem;
  margin-bottom: 2.5rem;
}
@media (min-width: 768px) {
  .logistic .logistic__ico {
    width: 6rem;
    height: 6rem;
    margin-bottom: 3.5rem;
  }
}
.logistic .logistic__ico svg {
  width: 100%;
  height: 100%;
}

.pageService .logistic .splide__head-text {
  font-size: 1.4rem;
}
@media (min-width: 1200px) {
  .pageService .logistic .splide__head-text {
    font-size: 2.4rem;
  }
}

.transport-route {
  overflow: hidden;
  background-color: #15110f;
}
.transport-route .transport-route__description {
  padding: 5rem 0 76%;
  position: relative;
  z-index: 5;
  max-width: 44rem;
}
@media (min-width: 768px) {
  .transport-route .transport-route__description {
    padding: 8% 0 12%;
  }
}
@media (min-width: 1200px) {
  .transport-route .transport-route__description {
    padding: 10% 0 20%;
    max-width: 64rem;
  }
}
.transport-route h2 {
  color: #f69e29;
  margin-bottom: 3.4rem;
}
@media (min-width: 768px) {
  .transport-route h2 {
    margin-bottom: 8rem;
  }
}
.transport-route .btn {
  padding: 1.5rem 6rem;
}
.transport-route .bg-img {
  left: 0;
  object-fit: contain;
  object-position: left bottom;
}
@media (min-width: 768px) {
  .transport-route .bg-img {
    left: 31%;
  }
}
@media (min-width: 1480px) {
  .transport-route .bg-img {
    left: 40%;
  }
}

.calculate-form {
  padding: 3rem 0;
  position: relative;
  z-index: 1;
  background: linear-gradient(to bottom, #f69e29 65%, #fff 65%);
}
.calculate-form.dark {
  background: linear-gradient(to bottom, #f69e29 65%, #27201c 65%);
}
@media (min-width: 768px) {
  .calculate-form {
    padding: 8rem 0 10rem;
  }
}
.calculate-form p {
  color: #161210;
}
@media (min-width: 768px) {
  .calculate-form .column:first-child {
    max-width: 57rem;
  }
}
.calculate-form .calculate-form-wrapper {
  background-color: #fff;
  padding: 2rem 1.7rem;
  box-shadow: 0px 5px 18px rgba(46, 46, 46, 0.14);
  border: 1px solid #e8e8e8;
  margin-top: 3rem;
}
@media (min-width: 768px) {
  .calculate-form .calculate-form-wrapper {
    padding: 6rem 2rem;
    margin-top: 8rem;
  }
}
.calculate-form .calculate-form-wrapper .tabs__form {
  max-width: 1340px;
  margin: 0 auto;
}
.calculate-form .form__input input {
  background-color: #f5f5f5;
  border: 2px solid #c6c6c6;
  padding: 2.5rem 1.5rem;
}
.calculate-form .form__input label {
  padding: 2.5rem 1.5rem;
}
.calculate-form .form__btn {
  height: 6rem;
}
@media (min-width: 768px) {
  .calculate-form .form__btn {
    height: 8rem;
  }
}

.possibility {
  padding: 3rem 0;
  background: linear-gradient(to top, #27201c 70px, #fff 70px);
}
@media (min-width: 768px) {
  .possibility {
    background: linear-gradient(to bottom, #fff 364px, #ededed 364px);
    padding: 3rem 0 8rem;
  }
}
.possibility h2 {
  text-align: left;
  margin-bottom: 3rem;
}
@media (min-width: 768px) {
  .possibility h2 {
    text-align: center;
    margin-bottom: 8rem;
  }
}
.possibility .splide__main-text {
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .possibility .splide__main-text {
    margin-bottom: 5rem;
  }
}

.press-center {
  padding: 3rem 0 3rem;
}
@media (min-width: 768px) {
  .press-center {
    padding: 7rem 0 9rem;
  }
}
.press-center h2 {
  margin-bottom: 2.5rem;
}
@media (min-width: 768px) {
  .press-center h2 {
    margin-bottom: 3.5rem;
  }
}

.navigate-desk-slider {
  margin-bottom: 2.4rem;
}
@media (min-width: 768px) {
  .navigate-desk-slider {
    margin-bottom: 4rem;
  }
}
.navigate-desk-slider ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.navigate-desk-slider .navigate-slider-item a {
  background-color: transparent;
  color: #868078;
  text-decoration: none;
  font-size: 1.4rem;
  padding: 0.8rem 1.5rem;
  margin-right: 1rem;
  transition: color 0.3s ease, background-color 0.3s ease;
}
@media (min-width: 768px) {
  .navigate-desk-slider .navigate-slider-item a {
    font-size: 1.8rem;
  }
}
.navigate-desk-slider .navigate-slider-item a:hover {
  background-color: #f69e29;
  color: #161210;
}
.navigate-desk-slider .navigate-slider-item.active-item a {
  background-color: #f69e29;
  color: #161210;
}

.footer {
  background-color: #161210;
  padding: 8rem 0 0rem;
}
.footer > * {
  font-size: 1.7rem;
}
.footer .wrapper {
  position: relative;
}
.footer .menu_logo {
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .footer .menu_logo {
    margin-bottom: 2rem;
  }
}
.footer .footer-logo {
  width: 100%;
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  .footer .footer-logo {
    margin-bottom: 4rem;
    padding-right: 1.5rem;
  }
}
@media (min-width: 1200px) {
  .footer .footer-logo {
    width: 29.7rem;
  }
}
.footer .footer-logo p {
  margin-bottom: 2rem;
}
@media (min-width: 1200px) {
  .footer .footer-logo p {
    margin-bottom: 6rem;
  }
}
.footer .footer-logo .confidence {
  position: absolute;
  bottom: 0;
}
@media (min-width: 768px) {
  .footer .footer-logo .confidence {
    position: relative;
  }
}
.footer .footer-logo .confidence a {
  text-align: left;
  display: block;
  text-decoration: underline;
}
.footer nav {
  margin-bottom: 4rem;
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media (min-width: 768px) {
  .footer nav {
    margin-bottom: 0;
  }
}
.footer nav > * {
  padding-right: 1.5rem;
}
.footer nav > *:last-child {
  padding-right: 0;
}
.footer a {
  color: #868078;
  transition: color 0.3s ease;
}
.footer a:hover {
  color: #fff;
}
.footer .footer-item {
  width: 100%;
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  .footer .footer-item {
    width: auto;
    margin-bottom: 4rem;
  }
}
.footer .footer-item .footer-link-head {
  text-align: left;
  display: block;
  color: #fff;
  font-size: 1.7rem;
  margin-bottom: 1.2rem;
}
@media (min-width: 1200px) {
  .footer .footer-item .footer-link-head {
    margin-bottom: 4.4rem;
  }
}
.footer .footer-item ul li {
  max-width: 100%;
  font-size: 1.7rem;
}
@media (min-width: 1200px) {
  .footer .footer-item ul li {
    margin-bottom: 1.5rem;
  }
}
@media (min-width: 1200px) {
  .footer .footer-item ul li {
    max-width: 29.7rem;
  }
}
.footer .footer-item ul .footer-link-item {
  font-size: 1.7rem;
}
.footer .footer-item:last-child ul {
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  .footer .footer-item:last-child ul {
    margin-bottom: 4rem;
  }
}
.footer .footer-social {
  padding-bottom: 5rem;
}
@media (min-width: 768px) {
  .footer .footer-social {
    padding-bottom: 0;
  }
}
.footer .footer-social span {
  margin-bottom: 2rem;
}
@media (min-width: 1200px) {
  .footer .footer-social span {
    margin-bottom: 3rem;
  }
}
.footer .social-container {
  display: flex;
  justify-content: space-between;
  max-width: 16rem;
  margin-top: 1.5rem;
}
.footer .social-container a {
  width: 3.3rem;
  height: 3.3rem;
  margin-right: 0;
}

.underground {
  background-color: #090706;
  padding: 4rem 1rem;
}
.underground p {
  margin-bottom: 0;
  color: #71665b;
  text-align: center;
  font-size: 1.6rem;
}

.punkt .columns-container {
  flex-wrap: wrap;
  align-items: flex-start;
}
@media (min-width: 768px) {
  .punkt .columns-container {
    margin-top: 0;
  }
  .punkt .columns-container .column:first-child {
    padding-top: 0;
  }
}
.punkt p {
  margin-bottom: 0;
}
.punkt .punkts-list {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .punkt .punkts-list {
    max-width: 44rem;
    margin: 0 0 0 auto;
  }
}
.punkt .punkts-list a:after {
  display: block;
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg width='64' height='16' viewBox='0 0 64 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M63.7071 8.70711C64.0976 8.31658 64.0976 7.68342 63.7071 7.29289L57.3431 0.928932C56.9526 0.538408 56.3195 0.538408 55.9289 0.928932C55.5384 1.31946 55.5384 1.95262 55.9289 2.34315L61.5858 8L55.9289 13.6569C55.5384 14.0474 55.5384 14.6805 55.9289 15.0711C56.3195 15.4616 56.9526 15.4616 57.3431 15.0711L63.7071 8.70711ZM0 9H63V7H0V9Z' fill='%2327201C'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  position: absolute;
  right: 2rem;
  width: 6.4rem;
  height: 1.6rem;
  top: 50%;
  transform: translate(0, -50%);
  color: #27201c;
}
.punkt .punkts-list a:hover {
  color: #27201c;
}
.punkt .punkts-list li,
.punkt .punkts-list a {
  font-size: 2.4rem;
  color: #fff;
}
@media (min-width: 1200px) {
  .punkt .punkts-list li,
  .punkt .punkts-list a {
    font-size: 3.2rem;
  }
}
.punkt .punkts-list li {
  position: relative;
}
.punkt .punkts-list li > span::before {
  display: block;
  content: "";
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translate(0, -50%);
  background-color: #3b312b;
}
.punkt .punkts-list li > span:after {
  display: block;
  content: "!";
  position: absolute;
  right: 3.2rem;
  top: 50%;
  transform: translate(0, -50%);
  color: #fff;
  font-size: 2rem;
}
.punkt .punkts-list span {
  text-align: left;
  display: block;
  padding: 1rem;
}
@media (min-width: 1200px) {
  .punkt .punkts-list span {
    padding: 1.6rem;
  }
}
.punkt .punkts-list span:hover {
  transition: all 0.3s ease;
  background-color: #f69e29;
}
.punkt .punkts-list span:hover ~ .hidden-discribe-block {
  opacity: 1;
  z-index: 1;
}

.hidden-discribe-block {
  padding: 1rem;
  opacity: 0;
  background-color: #3b312b;
  position: absolute;
  top: 100%;
  right: 0;
  max-width: 28rem;
  height: auto;
  font-size: 1.4rem;
  z-index: -1;
  color: #fff;
}

.benefits {
  background-color: #161210;
  padding: 5rem 0;
}
@media (min-width: 768px) {
  .benefits {
    padding: 7rem 0;
  }
}
.benefits h2 {
  color: #fff;
  margin-bottom: 5rem;
}
@media (min-width: 1200px) {
  .benefits h2 {
    margin-bottom: 7rem;
  }
}
@media (min-width: 768px) {
  .benefits__list {
    display: grid;
    gap: 3%;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (min-width: 768px) {
  .benefits__list-item {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: minmax(6rem, auto) 1fr;
    position: relative;
  }
  .benefits__list-item::after {
    content: "";
    display: block;
    position: absolute;
    width: 1px;
    height: 100%;
    right: 0;
    background-color: #27201c;
  }
  .benefits__list-item:last-child::after {
    display: none;
  }
}
@media (min-width: 1200px) {
  .benefits__list-item {
    grid-template-rows: minmax(8rem, auto) 1fr;
  }
}
.benefits__header {
  color: #f69e29;
  font-weight: 600;
  font-size: 1.8rem;
  margin-bottom: 1rem;
  padding-right: 2rem;
}
@media (min-width: 1200px) {
  .benefits__header {
    font-size: 2.8rem;
    margin-bottom: 0;
  }
}
.benefits__text {
  margin-bottom: 5rem;
}
@media (min-width: 768px) {
  .benefits__text {
    margin-bottom: 2rem;
    padding-right: 2rem;
    max-width: 35rem;
  }
}

.table {
  padding: 4rem 0 0;
  background-color: #f6f6f6;
}
@media (min-width: 768px) {
  .table {
    padding: 7rem 0 0;
  }
}
.table .table-wrapper {
  width: 100%;
  max-width: 1340px;
  margin: 0 auto;
  position: relative;
  padding-bottom: 3.5rem;
}
.table p {
  margin-bottom: 0;
  padding-bottom: 3.5rem;
}
.table .table-conteiner {
  overflow: auto;
  position: relative;
}
.table table {
  width: 100%;
  word-break: break-all;
}
.table thead {
  background-color: #f69e29;
}
.table th,
.table td {
  text-align: left;
  width: auto;
  padding: 1.2rem 1.7rem;
  font-size: 1.4rem;
  min-width: 20rem;
}
@media (min-width: 1200px) {
  .table th,
  .table td {
    padding: 3rem 1rem;
    font-size: 2.4rem;
  }
}
.table th {
  font-weight: normal;
  color: #161210;
}
.table th.fixed-td {
  background-color: #f69e29;
}
.table td {
  background-color: #f6f6f6;
  font-weight: 300;
  color: #5c5751;
  border: none;
}
.table tr {
  border-bottom: 1px solid #b1b1b1;
}
.table .custom-scroll thead {
  background-color: #27201c;
}
.table .custom-scroll th {
  color: #fff;
}
.table .custom-scroll td {
  background-color: #ebebeb;
}
.table .custom-scroll td.fixed-td {
  background-color: #f6f6f6;
}
.table .custom-scroll td {
  min-width: calc((100vw - 16rem) / 2);
}
@media (min-width: 400px) {
  .table .custom-scroll td {
    min-width: 50vw;
  }
}
@media (min-width: 768px) {
  .table .custom-scroll td {
    min-width: 33.3333333333vw;
  }
}
@media (min-width: 1360px) {
  .table .custom-scroll td {
    min-width: 335px;
  }
}
.table .custom-scroll .fixed-td {
  min-width: 16rem;
  position: sticky;
  left: 0;
  top: 0;
}
@media (min-width: 400px) {
  .table .custom-scroll .fixed-td {
    min-width: 50vw;
  }
}
@media (min-width: 768px) {
  .table .custom-scroll .fixed-td {
    min-width: 33.3333333333vw;
  }
}
@media (min-width: 1360px) {
  .table .custom-scroll .fixed-td {
    min-width: 335px;
  }
}
.table .custom-scroll ~ .table-nav {
  display: flex;
}
.table .table-nav {
  background-color: #27201c;
  display: none;
  justify-content: space-between;
  align-items: center;
  top: 0;
  right: 0;
  padding: 1rem 0;
  position: absolute;
  z-index: 1;
}
@media (min-width: 1200px) {
  .table .table-nav {
    padding: 3.5rem 2rem;
  }
}
.table .tableArr {
  width: 1.5rem;
  height: 2rem;
  margin: 0 0.7rem;
  cursor: pointer;
}
.table .tableArr svg {
  width: 100%;
  height: 100%;
  display: block;
}
.table .tableArr.moveLeft {
  transform: rotate(180deg);
}

.feature {
  background-color: #27201c;
  padding: 2rem 0;
}
@media (min-width: 768px) {
  .feature {
    padding: 3rem 0 6rem;
  }
}
.feature h2 {
  color: #f69e29;
}
.feature .feature-item {
  background-color: #161210;
  padding: 4rem 2rem;
  margin-bottom: 2.4rem;
}
@media (min-width: 768px) {
  .feature .feature-item {
    padding: 4rem;
    min-height: 30rem;
  }
}
.feature .column-grid {
  margin-bottom: 2.4rem;
}
@media (min-width: 768px) {
  .feature .column-grid {
    display: flex;
    align-items: center;
    min-height: 30rem;
  }
}
.feature .feature-header {
  color: #fff;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1.4rem;
}
@media (min-width: 1200px) {
  .feature .feature-header {
    font-size: 2.4rem;
    margin-bottom: 2rem;
  }
}

.stages {
  padding: 9rem 0 0;
  background: linear-gradient(to top, #27201c 100px, #fff 100px);
}
.stages .wrapper > p {
  max-width: 90rem;
  margin-bottom: 10rem;
}
.stages__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1340px;
  margin: 0 auto;
}
.stages__list-item {
  counter-increment: section;
  padding: 3rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  box-shadow: 0px 5px 18px rgba(46, 46, 46, 0.03);
  border: 1px solid #dfdfdf;
  border-bottom: 5px solid #e49f46;
}
@media (min-width: 768px) {
  .stages__list-item {
    width: 49%;
    margin-bottom: 2.5rem;
  }
}
.stages__list-item::before {
  content: counter(section);
  display: block;
  font-size: 10rem;
  padding-right: 3rem;
  font-weight: 700;
  color: #e49f46;
  line-height: 1;
}
.stages__header {
  color: #402706;
  font-size: 1.8rem;
  font-weight: normal;
  margin-bottom: 1rem;
}
@media (min-width: 1200px) {
  .stages__header {
    font-size: 2.4rem;
  }
}

.services {
  padding: 4rem 0;
}
@media (min-width: 768px) {
  .services {
    padding: 8rem 0;
  }
}
.services .columns-container {
  align-items: flex-start;
}
.services h2 {
  margin-bottom: 3rem;
}
@media (min-width: 1200px) {
  .services h2 {
    margin-bottom: 8rem;
  }
}
.services .media-container {
  width: 100%;
  margin-bottom: 4.5rem;
}
@media (min-width: 1200px) {
  .services .media-container {
    margin-bottom: 9rem;
  }
}
.services .column:first-child {
  margin-bottom: 3rem;
}
.services .column:first-child > * {
  max-width: 544px;
}
.services__header {
  color: #fff;
  margin-bottom: 6rem;
  font-size: 1.6rem;
  font-weight: 300;
}
@media (min-width: 1200px) {
  .services__header {
    font-size: 2.4rem;
  }
}
.services__list-item {
  color: #fff;
  font-size: 1.4rem;
  font-weight: 300;
  margin-bottom: 4rem;
  position: relative;
  padding-left: 5rem;
}
@media (min-width: 1200px) {
  .services__list-item {
    font-size: 1.8rem;
    margin-bottom: 6rem;
  }
}
.services__list-item:before {
  content: "";
  display: inline-block;
  margin-right: 4rem;
  width: 1.8rem;
  height: 1.2rem;
  left: 0;
  top: 0;
  border-left: 6px solid #e49f46;
  border-bottom: 6px solid #e49f46;
  transform: rotate(-45deg);
  position: absolute;
}

.call-order {
  background-color: #ededed;
  padding: 4rem 0 3rem;
}
@media (min-width: 768px) {
  .call-order {
    padding: 8rem 0;
  }
}
.call-order h2 {
  text-align: left;
  margin-bottom: 2.7rem;
}
@media (min-width: 768px) {
  .call-order h2 {
    text-align: center;
  }
}
.call-order .call-order-descript {
  font-size: 1.6rem;
  color: #5c5751;
  text-align: left;
  font-weight: normal;
  margin-bottom: 4rem;
}
@media (min-width: 768px) {
  .call-order .call-order-descript {
    text-align: center;
  }
}
@media (min-width: 1200px) {
  .call-order .call-order-descript {
    font-size: 1.8rem;
    margin-bottom: 5rem;
  }
}
.call-order .popup-calc__form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.call-order .popup-calc__form-row > * {
  width: 100%;
}
@media (min-width: 768px) {
  .call-order .popup-calc__form-row > * {
    width: 32%;
  }
}
.call-order .popup-calc__form-row:last-child {
  flex-wrap: wrap-reverse;
}
.call-order .popupForm label,
.call-order .popupForm input {
  font-weight: normal;
}
.call-order .form__btn {
  background-color: #e49f46;
  color: #fff;
  text-transform: none;
  margin-top: 1.4rem;
  transition: color 0.3s ease;
}
@media (min-width: 768px) {
  .call-order .form__btn {
    margin-top: 0;
  }
}
.call-order .form__btn:hover {
  color: #0b0b0b;
}
.call-order .operator {
  display: flex;
}
.call-order .input-checkbox {
  max-width: 32rem;
}

.what-else {
  background-color: #ededed;
}
@media (min-width: 768px) {
  .what-else .color-wrapper {
    padding: 4rem 0;
  }
}
.what-else .color-wrapper:first-child {
  padding: 4rem 0 0;
  background: linear-gradient(to bottom, #fff 70%, #ededed 70%);
}
@media (min-width: 768px) {
  .what-else .color-wrapper:first-child {
    background: #fff;
    padding: 8rem 0 4rem;
  }
}
.what-else .color-wrapper:nth-child(2) {
  padding: 4rem 0;
}
.what-else .desctopSplide {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .what-else .desctopSplide {
    margin-bottom: 2rem;
  }
}
.what-else .fz-24 {
  margin-bottom: 2.5rem;
  color: #342415;
}
@media (min-width: 768px) {
  .what-else .fz-24 {
    margin-bottom: 4rem;
  }
}
.what-else .splide__slide {
  background-color: #fff;
  border: 1px solid #e8e8e8;
  box-shadow: 0px 5px 18px rgba(46, 46, 46, 0.03);
  border-bottom: 4px solid #e49f46;
}
.what-else .splide__head-text {
  color: #342415;
  font-size: 2.4rem;
  flex-grow: 1;
}

.dark-form {
  background-color: #161210;
  padding: 4rem 0;
}
@media (min-width: 768px) {
  .dark-form {
    padding: 7rem 0;
  }
}
.dark-form h2 {
  color: #f69e29;
}
.dark-form .form-head-columns {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  .dark-form .form-head-columns {
    margin-bottom: 6rem;
  }
}
.dark-form .form-column:first-child {
  width: 100%;
}
@media (min-width: 768px) {
  .dark-form .form-column:first-child {
    width: 64%;
  }
}
.dark-form .form-column:first-child p {
  max-width: 65rem;
}
.dark-form .form-column:last-child {
  display: none;
}
@media (min-width: 768px) {
  .dark-form .form-column:last-child {
    display: block;
    width: 32%;
  }
}
.dark-form .form-column .form-image {
  width: 100%;
  width: 15rem;
  height: 15rem;
  margin: 0 auto;
}
@media (min-width: 1200px) {
  .dark-form .form-column .form-image {
    width: 25rem;
    height: 25rem;
  }
}
.dark-form .form-column .form-image svg {
  display: block;
  width: 100%;
  height: 100%;
}
.dark-form .dark__form {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 2rem;
}
@media (min-width: 768px) {
  .dark-form .dark__form {
    grid-template-columns: repeat(4, 1fr);
  }
}
.dark-form .dark__form .form__input,
.dark-form .dark__form .form__btn {
  margin-bottom: 0;
}
.dark-form .dark__form .form__input input,
.dark-form .dark__form .form__input textarea {
  color: #fff;
  background-color: #27201c;
}
.dark-form .dark__form .mobile-position {
  grid-row: 6;
}
@media (min-width: 768px) {
  .dark-form .dark__form .mobile-position {
    grid-row: auto;
  }
}
.dark-form .dark__form .operator {
  display: flex;
}
.dark-form .darkFormColuns {
  width: 100%;
}
.dark-form .darkFormColuns__wide {
  width: 100%;
}
@media (min-width: 768px) {
  .dark-form .darkFormColuns__wide {
    grid-column: 1/3;
  }
}

.imageText {
  background-color: #27201c;
}
.imageText h2 {
  color: #f69e29;
}
.imageText .imageText-wrapper {
  max-width: 1680px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
}
@media (min-width: 768px) {
  .imageText .imageText-wrapper {
    grid-template-columns: 1fr 1fr;
  }
}
.imageText .text-column {
  align-self: center;
  padding: 4rem 2rem;
  max-width: 67rem;
}
@media (min-width: 1200px) {
  .imageText .text-column {
    padding: 10rem 2rem 10rem 7rem;
  }
}
.imageText .image-column {
  object-fit: cover;
  object-position: center center;
}
@media (min-width: 768px) {
  .imageText.imageWhideText .imageText-wrapper {
    grid-template-columns: 58% 42%;
  }
}
.imageText.imageWhideText .text-column {
  max-width: 814px;
  margin: 0 0 0 auto;
  grid-row: 2;
}
@media (min-width: 768px) {
  .imageText.imageWhideText .text-column {
    grid-row: auto;
  }
}
@media (min-width: 1200px) {
  .imageText.imageWhideText .text-column {
    padding: 10rem 10rem 10rem 1rem;
  }
}

.impExp {
  padding: 4rem 0 3rem;
}
@media (min-width: 768px) {
  .impExp {
    padding: 8rem 0 6rem;
  }
}
.impExp h2 {
  margin-bottom: 3rem;
}
@media (min-width: 1200px) {
  .impExp h2 {
    margin-bottom: 8rem;
  }
}
.impExp .impExp__columns-container {
  display: grid;
  gap: 1.6rem;
  grid-template-columns: 1fr;
}
@media (min-width: 768px) {
  .impExp .impExp__columns-container {
    grid-template-columns: 1fr 1fr;
    gap: 4rem 2rem;
  }
}
.impExp .impExp__columns-container .column:nth-child(2) {
  grid-row-start: 1;
}
@media (min-width: 768px) {
  .impExp .impExp__columns-container .column:nth-child(2) {
    grid-row-start: auto;
  }
}
.impExp .impExp-wrap {
  border: 1px solid #dfdfdf;
  padding: 2.6rem;
  height: 100%;
}
@media (min-width: 768px) {
  .impExp .impExp-wrap {
    min-height: 42rem;
  }
}
@media (min-width: 1200px) {
  .impExp .impExp-wrap {
    padding: 5rem;
  }
}
.impExp .impExp-wrap:nth-child(3) {
  background-color: #f2f2f2;
}
.impExp .impExp-image-decor {
  height: 100%;
  margin: 0.7rem 0 0 0.7rem;
  position: relative;
}
@media (min-width: 768px) {
  .impExp .impExp-image-decor {
    margin: 1.5rem 0 0 1.5rem;
  }
}
.impExp .impExp-image-decor picture,
.impExp .impExp-image-decor img {
  position: relative;
  z-index: 2;
}
.impExp .impExp-image-decor .img-cover {
  object-fit: cover;
  object-position: center center;
}
.impExp .impExp-image-decor::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f69e29;
  top: -0.7rem;
  left: -0.7rem;
  z-index: 0;
}
@media (min-width: 768px) {
  .impExp .impExp-image-decor::after {
    top: -1.5rem;
    left: -1.5rem;
  }
}
.impExp .impExp-header {
  font-size: 1.8rem;
  color: #000;
  margin-bottom: 1.5rem;
}
@media (min-width: 1200px) {
  .impExp .impExp-header {
    font-size: 3.6rem;
    margin-bottom: 3rem;
  }
}

.additional-serv {
  background-color: #27201c;
  padding: 0 0 4rem 0;
}
@media (min-width: 768px) {
  .additional-serv {
    padding: 8rem 0;
  }
}
.additional-serv .imageText-wrapper {
  gap: 2rem;
}
.additional-serv h2 {
  color: #f69e29;
  padding: 0 1rem;
  position: relative;
  z-index: 2;
}
@media (min-width: 768px) {
  .additional-serv h2 {
    max-width: 67rem;
    padding: 0 0 0 1rem;
    margin: 0 0 10rem auto;
  }
}
.additional-serv .imageText-column {
  position: relative;
  display: flex;
  flex-wrap: wrap-reverse;
}
@media (min-width: 768px) {
  .additional-serv .imageText-column {
    display: block;
  }
}
.additional-serv .imageText-column picture,
.additional-serv .imageText-column img {
  width: 100%;
  margin-bottom: 4rem;
  height: auto;
}
@media (min-width: 768px) {
  .additional-serv .imageText-column picture,
  .additional-serv .imageText-column img {
    margin-bottom: 0;
    position: absolute;
    bottom: 0;
    height: 90%;
    width: 100%;
  }
}
.additional-serv .additional-serv_list {
  padding: 1rem;
}
@media (min-width: 768px) {
  .additional-serv .additional-serv_list {
    max-width: 67rem;
    padding: 0 1rem 0 0;
  }
}
.additional-serv .additional-serv_list-item {
  padding: 2rem 1.8rem;
  margin-bottom: 1.8rem;
  background-color: #161210;
  border-right: 5px solid #e49f46;
}
.additional-serv .additional-serv_list-item:last-child {
  margin-bottom: 0;
}
@media (min-width: 1200px) {
  .additional-serv .additional-serv_list-item {
    padding: 3rem;
  }
}
.additional-serv .additional-serv_list-header {
  color: #fff;
  font-size: 1.8rem;
  margin-bottom: 2rem;
}
@media (min-width: 1200px) {
  .additional-serv .additional-serv_list-text {
    font-size: 1.7rem;
  }
}

.geography {
  background-color: #15110f;
  padding: 5rem 0;
}
.geography h2 {
  color: #f69e29;
  margin-bottom: 3rem;
}
@media (min-width: 768px) {
  .geography h2 {
    margin-bottom: 9rem;
  }
}
.geography .geography-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}
@media (min-width: 768px) {
  .geography .geography-wrapper {
    flex-direction: row;
  }
}
.geography .geography-column {
  width: 100%;
}
@media (min-width: 768px) {
  .geography .geography-column:first-child {
    width: 66%;
  }
  .geography .geography-column:last-child {
    width: 32%;
  }
}
.geography .geography-img {
  width: 100%;
  height: auto;
  max-width: 877px;
}
@media (min-width: 1640px) {
  .geography .geography-img {
    margin-left: -10rem;
  }
}
.geography .geography-item-header {
  color: #fff;
  font-size: 2.4rem;
  margin-bottom: 1rem;
}
.geography .geography-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}
.geography .geography-row p {
  font-size: 1.6rem;
  margin-bottom: 0;
  color: #fff;
}
.geography .geography-row p:first-child {
  margin-right: 1rem;
}
.geography .geography-item {
  border-bottom: 1px solid #868078;
  margin-bottom: 2rem;
}
.geography .geography-item:last-child {
  border-bottom: none;
}
.geography .btn__orange {
  margin: 0 1rem 0 0;
  max-width: 18.7rem;
  width: 100%;
  transition: color 0.3s ease;
}
.geography .more_link {
  text-decoration: underline;
}

.clients {
  background-color: #fff;
  padding: 4rem 0 3rem;
}
@media (min-width: 768px) {
  .clients {
    padding: 8rem 0 3rem;
  }
}
.clients .clients__wrapper {
  max-width: 1510px;
  margin: 0 auto;
}
.clients .clients__list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.clients .client__item {
  width: 50%;
  transition: 0.3s all ease;
  opacity: 0.4;
  filter: grayscale(1);
  margin-bottom: 0;
}
.clients .client__item:hover {
  opacity: 1;
  filter: grayscale(0);
}
.clients .client__item img {
  max-width: 25.4rem;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .clients .client__item {
    width: 25%;
    margin-bottom: 2rem;
  }
  .clients .client__item img {
    max-width: 25.4rem;
    margin: 0 auto;
  }
}
.clients .clients-wrapper {
  max-width: 1465px;
  margin: 0 auto;
  padding: 0 1rem;
}

.review {
  padding: 3rem 0;
  background: linear-gradient(to bottom, #f69e29 400px, #ededed 400px);
}
@media (min-width: 768px) {
  .review {
    background: linear-gradient(to bottom, #f69e29 450px, #ededed 450px);
    padding: 6rem 0 8rem;
  }
}
.review h2 {
  text-align: left;
  margin-bottom: 3rem;
}
@media (min-width: 768px) {
  .review h2 {
    text-align: center;
    margin-bottom: 6rem;
  }
}
.review .splide__main-text {
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .review .splide__main-text {
    margin-bottom: 5rem;
  }
}
.review .splide__main-text {
  margin-bottom: 0;
}
.review .persone-img {
  width: 8.8rem;
  height: 8.8rem;
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  .review .persone-img {
    margin-bottom: 0;
    margin-right: 1rem;
  }
}
.review .splide__content {
  padding: 4rem 3rem;
}
.review .persone-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  .review .persone-card {
    margin-bottom: 4rem;
    flex-direction: row;
    align-items: center;
  }
}
.review .persone-name {
  max-width: 18.9rem;
  font-size: 1.8rem;
  color: #402706;
}
@media (min-width: 768px) {
  .review .persone-name {
    font-size: 2.4rem;
  }
}

.divisions,
.contacts {
  background-color: #ededed;
  padding: 2rem 0 13rem;
}
.divisions h2,
.contacts h2 {
  margin-bottom: 2.5rem;
}
@media (min-width: 1200px) {
  .divisions h2,
  .contacts h2 {
    margin-bottom: 8rem;
  }
}
.divisions .card,
.contacts .card {
  margin-bottom: 3rem;
}
@media (min-width: 768px) {
  .divisions .card,
  .contacts .card {
    margin-bottom: 2%;
  }
}
.divisions .card-wrapper,
.contacts .card-wrapper {
  background-color: #fff;
  padding: 2.5rem 1.8rem;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.divisions .card-title,
.contacts .card-title {
  font-size: 1.8rem;
  color: #000;
  margin-bottom: 1.8rem;
}
@media (min-width: 1200px) {
  .divisions .card-title,
  .contacts .card-title {
    font-size: 3.6rem;
    margin-bottom: 5rem;
  }
}
.divisions .card-text,
.contacts .card-text {
  color: #1c1309;
  font-size: 1.4rem;
  margin-bottom: 5rem;
}
@media (min-width: 1200px) {
  .divisions .card-text,
  .contacts .card-text {
    font-size: 2rem;
  }
}
.divisions .card-adress a,
.contacts .card-adress a {
  color: #1c1309;
  font-size: 1.4rem;
  display: block;
  text-align: left;
}
.divisions .splide__img,
.contacts .splide__img {
  height: auto;
}
.divisions .card-time,
.contacts .card-time {
  margin-bottom: 0;
  color: #1c1309;
  font-size: 1.4rem;
  font-weight: normal;
}

.blog,
.blog-post,
.news-post,
.service-post,
.shipping-post,
.contacts {
  background-color: #ededed;
  padding: 4rem 0;
}
.blog .card-wrapper,
.blog-post .card-wrapper,
.news-post .card-wrapper,
.service-post .card-wrapper,
.shipping-post .card-wrapper,
.contacts .card-wrapper {
  padding: 2rem 1.5rem;
}
@media (min-width: 1200px) {
  .blog .card-wrapper,
  .blog-post .card-wrapper,
  .news-post .card-wrapper,
  .service-post .card-wrapper,
  .shipping-post .card-wrapper,
  .contacts .card-wrapper {
    padding: 3rem;
  }
}
.blog .wrapper-4,
.blog-post .wrapper-4,
.news-post .wrapper-4,
.service-post .wrapper-4,
.shipping-post .wrapper-4,
.contacts .wrapper-4 {
  padding-bottom: 3rem;
}
@media (min-width: 768px) {
  .blog .wrapper-4,
  .blog-post .wrapper-4,
  .news-post .wrapper-4,
  .service-post .wrapper-4,
  .shipping-post .wrapper-4,
  .contacts .wrapper-4 {
    padding-bottom: 8rem;
  }
}

.news {
  padding: 4rem 0 8rem;
  background-color: #fff;
}
@media (min-width: 768px) {
  .news {
    background: linear-gradient(to bottom, #ededed 352px, #fff 352px);
  }
}
.news .card-wrapper {
  padding: 2rem 0;
}
@media (min-width: 1200px) {
  .news .card-wrapper {
    padding: 3rem 0;
  }
}
.news .wrapper-4 {
  padding-bottom: 3rem;
}
@media (min-width: 768px) {
  .news .wrapper-4 {
    padding-bottom: 8rem;
  }
}

.blog-post,
.news-post {
  background-color: #fff;
}
@media (min-width: 992px) {
  .blog-post,
  .news-post {
    background-color: #ededed;
  }
}

.navigate-pages {
  margin-bottom: 2.4rem;
}
@media (min-width: 768px) {
  .navigate-pages {
    margin-bottom: 4rem;
  }
}
.navigate-pages ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.navigate-pages .navigate-title {
  margin-bottom: 1.5rem;
  font-size: 1.4rem;
  color: #27201c;
  font-weight: normal;
}
@media (min-width: 768px) {
  .navigate-pages .navigate-title {
    font-size: 1.8rem;
    margin-bottom: 2rem;
  }
}
.navigate-pages .navigate-pages-item {
  margin-bottom: 0.7em;
}
.navigate-pages .navigate-pages-item a {
  background-color: #fff;
  color: #161210;
  text-decoration: none;
  font-size: 1.4rem;
  padding: 0.8rem 1.5rem;
  margin-right: 0.8rem;
  transition: color 0.3s ease, background-color 0.3s ease;
  display: block;
}
@media (min-width: 768px) {
  .navigate-pages .navigate-pages-item a {
    margin-right: 2.3rem;
    font-size: 1.7rem;
  }
}
.navigate-pages .navigate-pages-item a:hover {
  background-color: #f69e29;
}
.navigate-pages .navigate-pages-item.active-item a {
  background-color: #f69e29;
}
.navigate-pages.navigate-tags .navigate-pages-item a {
  padding: 0.3rem 1rem;
  font-size: 1.4rem;
  margin-right: 1rem;
}
@media (min-width: 768px) {
  .navigate-pages.navigate-tags .navigate-pages-item a {
    margin-right: 2rem;
  }
}

.pages-breadcrump {
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  .pages-breadcrump {
    margin-bottom: 3rem;
  }
}
.pages-breadcrump ul {
  display: flex;
  flex-wrap: wrap;
}
.pages-breadcrump li {
  color: #27201c;
  font-size: 1.3rem;
  margin-bottom: 1rem;
  display: block;
  overflow: hidden;
}
@media (min-width: 768px) {
  .pages-breadcrump li {
    font-size: 1.6rem;
  }
}
.pages-breadcrump li a {
  line-height: 1;
  color: #27201c;
  vertical-align: middle;
}
.pages-breadcrump li:after {
  content: "›";
  font-size: 1.5rem;
  line-height: 1;
  vertical-align: middle;
  color: #f69e29;
  display: inline-block;
  margin: 0 0.9rem;
}
@media (min-width: 768px) {
  .pages-breadcrump li:after {
    margin: 0 2rem;
  }
}
.pages-breadcrump li:last-child a {
  max-width: calc(100vw - 2rem);
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (min-width: 768px) {
  .pages-breadcrump li:last-child a {
    max-width: 100%;
  }
}
.pages-breadcrump li:last-child::after {
  display: none;
}

.paggination-pages .paggination-list {
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.paggination-pages .paggination-list-itemNum {
  font-size: 1.4rem;
  background-color: #fff;
  color: #342415;
  width: 3.3rem;
  height: 3.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  margin-right: 1.8rem;
}
.paggination-pages .paggination-list-itemMore {
  font-size: 1.4rem;
  color: #342415;
  font-weight: 600;
}

.card-img-container {
  position: relative;
}
.card-img-container span {
  position: absolute;
  background-color: #f69e29;
  color: #161210;
  display: block;
  padding: 0.5rem;
  left: 3rem;
  bottom: 1.5rem;
  font-size: 1.2rem;
  font-weight: 500;
}

.card-wrapper {
  background-color: #fff;
  flex-grow: 1;
  display: grid;
  grid-template-rows: 2rem minmax(8rem, auto) 1fr 2rem;
}

.card__date {
  font-size: 1rem;
  color: #aaaaaa;
  font-weight: 500;
  margin-bottom: 1rem;
}

.card__head-text {
  font-size: 1.5rem;
  line-height: 1.4;
  margin-bottom: 1.5rem;
  font-weight: 500;
  color: #000;
}

.card__main-text {
  font-size: 1.2rem;
  font-weight: 100;
  margin-bottom: 4rem;
}

.card__link {
  color: #f69e29;
  font-size: 1.4rem;
  font-weight: 500;
  text-align: left;
}

.blog-post-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column-reverse;
}
@media (min-width: 992px) {
  .blog-post-container {
    flex-direction: row;
  }
}

.blog-post-similar {
  background-color: #fff;
}
@media (min-width: 992px) {
  .blog-post-similar {
    max-width: 320px;
    width: 32%;
    margin-right: 2rem;
  }
}
.blog-post-similar__title {
  padding: 2rem 3rem;
  font-size: 1.5rem;
  color: #000;
  font-weight: normal;
  margin-bottom: 0;
}
.blog-post-similar__list-item {
  border-top: 1px solid #dcdcdc;
  padding: 2rem 3rem;
  position: relative;
}
.blog-post-similar__list-link {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.blog-post-similar__list-data {
  font-size: 1rem;
  color: #aaaaaa;
  margin-bottom: 0.7rem;
}
.blog-post-similar__list-header {
  font-size: 1.5rem;
  color: #000;
  font-weight: normal;
  margin-bottom: 1.3rem;
}
.blog-post-similar__list-text {
  font-size: 1.2rem;
  color: #000;
}

.blog-post__article {
  width: 100%;
  background-color: #fff;
}
.blog-post__article-img {
  margin-bottom: 3rem;
}
.blog-post__article-wrapper {
  max-width: 875px;
  margin: 0 auto;
  padding: 1rem;
}
.blog-post__article-title {
  letter-spacing: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  color: #000;
  font-weight: normal;
}
.blog-post__article-nav {
  margin: 2rem 0;
}
.blog-post__article-navItem {
  counter-increment: navItem;
}
.blog-post__article-navItem a {
  padding-left: 2rem;
  font-size: 1.4rem;
  color: #aaaaaa;
  position: relative;
}
.blog-post__article-navItem a::before {
  content: counter(navItem);
  counter-increment: section;
  position: absolute;
  left: 0;
  top: 0;
}
.blog-post__article-text {
  font-size: 1.4rem;
  line-height: 2;
  color: #000;
  margin-bottom: 4rem;
}

.news-post {
  padding-bottom: 0;
}
.news-post .blog-post__article-wrapper {
  max-width: 1160px;
  padding: 1rem;
}
@media (min-width: 992px) {
  .news-post .blog-post__article-wrapper {
    padding: 4rem 1rem;
  }
}
.news-post .blog-post__article {
  margin-bottom: 4rem;
}
.news-post .splide__content,
.news-post .splide__slide {
  background-color: #fff;
  text-align: left;
}
.news-post .splide__head-text {
  color: #000;
  font-size: 1.5rem;
}
.news-post .splide__content {
  display: grid;
  grid-template-rows: 2rem minmax(8rem, auto) 1fr 2rem;
}
.news-post .splide__link {
  display: inline-block;
}

.back-container {
  position: relative;
}
.back-container .back-link {
  color: #000;
  font-size: 1.5rem;
  margin-bottom: 3rem;
  display: block;
  text-align: left;
}
@media (min-width: 1200px) {
  .back-container .back-link {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 5rem;
  }
  .back-container .back-link span {
    display: none;
  }
}
@media (min-width: 1200px) {
  .back-container .main-text-page {
    padding-left: 3rem;
  }
}

.news-post-title {
  margin-bottom: 3rem;
  font-size: 1.5rem;
  color: #000;
  font-weight: normal;
}

.desctopSplide .splide__main-text {
  font-size: 1.2rem;
}
.desctopSplide .splide__link {
  font-size: 1.4rem;
}

.bg-splide-color {
  background-color: #ededed;
  max-width: 1360px;
  margin: 0 auto;
  padding: 3rem 1rem;
}

.sub-header {
  margin-bottom: 2.5rem;
}
@media (min-width: 1200px) {
  .sub-header {
    margin-bottom: 4rem;
  }
}

.service-post .card-wrapper,
.shipping-post .card-wrapper {
  border-bottom: 4px solid #e49f46;
  grid-template-rows: minmax(8rem, auto) 1fr 2rem;
  padding: 4.5rem 3rem 3rem;
}
.service-post .card__head-text,
.shipping-post .card__head-text {
  font-size: 2.4rem;
  line-height: 3.6rem;
}
.service-post .card__main-text,
.service-post .card__data,
.shipping-post .card__main-text,
.shipping-post .card__data {
  color: #aaaaaa;
  font-size: 1.8rem;
  font-weight: normal;
  line-height: 1.1;
}
.service-post .card__link a,
.shipping-post .card__link a {
  color: #e49f46;
  font-size: 1.8rem;
  font-weight: normal;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.service-post .card__link a::before,
.shipping-post .card__link a::before {
  content: "";
  position: absolute;
  transition: left 0.3s ease;
  left: -100%;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #e49f46;
  display: block;
}
.service-post .card__link a:hover::before,
.shipping-post .card__link a:hover::before {
  left: 0%;
}
.service-post .img-decor,
.shipping-post .img-decor {
  position: absolute;
  width: 6rem;
  height: 6rem;
  left: 3rem;
  bottom: -3rem;
}

.shipping-post .card-wrapper {
  grid-template-rows: minmax(8rem, auto) 4rem 1fr 2rem;
}

.contacts-text {
  color: #000000;
  padding: 2rem 0;
}
@media (min-width: 768px) {
  .contacts-text {
    padding: 4rem 0 8rem;
  }
}

.contacts-form {
  background-color: #e9e5e2;
}
.contacts-form .main-text-page {
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  .contacts-form .main-text-page {
    margin-bottom: 5rem;
  }
}
@media (min-width: 768px) {
  .contacts-form .column {
    max-width: 60rem;
  }
}
.contacts-form .input-checkbox {
  max-width: 100%;
}
.contacts-form .columns-container {
  align-items: stretch;
}
.contacts-form .contact-textarea {
  height: calc(100% - 2rem);
}
.contacts-form .contact-textarea textarea {
  font-weight: normal;
}

.contacts .wrapper-3 {
  padding-bottom: 0;
}
@media (min-width: 768px) {
  .contacts .wrapper-3 {
    padding-bottom: 10rem;
  }
}

.main-text-page {
  font-size: 2.4rem;
  margin-bottom: 2.5rem;
  letter-spacing: 0;
}
@media (min-width: 1200px) {
  .main-text-page {
    margin-bottom: 4rem;
    letter-spacing: -0.2rem;
    font-size: 4.8rem;
  }
}

.splide__container {
  box-sizing: border-box;
  position: relative;
}

.splide__list {
  backface-visibility: hidden;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
}

.splide.is-initialized:not(.is-active) .splide__list {
  display: block;
}

.splide__pagination {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0;
  pointer-events: none;
}

.splide__pagination li {
  display: inline-block;
  line-height: 1;
  list-style-type: none;
  margin: 0;
  pointer-events: auto;
}

.splide:not(.is-overflow) .splide__pagination {
  display: none;
}

.splide__progress__bar {
  width: 0;
}

.splide {
  position: relative;
  visibility: hidden;
}

.splide.is-initialized, .splide.is-rendered {
  visibility: visible;
}

.splide__slide {
  backface-visibility: hidden;
  box-sizing: border-box;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  list-style-type: none !important;
  margin: 0;
  position: relative;
}

.splide__slide img {
  vertical-align: bottom;
}

.splide__spinner {
  animation: splide-loading 1s linear infinite;
  border: 2px solid #999;
  border-left-color: transparent;
  border-radius: 50%;
  bottom: 0;
  contain: strict;
  display: inline-block;
  height: 20px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
}

.splide__sr {
  clip: rect(0 0 0 0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.splide__toggle.is-active .splide__toggle__play, .splide__toggle__pause {
  display: none;
}

.splide__toggle.is-active .splide__toggle__pause {
  display: inline;
}

.splide__track {
  overflow: hidden;
  position: relative;
  z-index: 0;
}

@keyframes splide-loading {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(1turn);
  }
}
.splide__track--draggable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.splide__track--fade > .splide__list > .splide__slide {
  margin: 0 !important;
  opacity: 0;
  z-index: 0;
}

.splide__track--fade > .splide__list > .splide__slide.is-active {
  opacity: 1;
  z-index: 1;
}

.splide--rtl {
  direction: rtl;
}

.splide__track--ttb > .splide__list {
  display: block;
}

.splide__arrow {
  -ms-flex-align: center;
  align-items: center;
  background: #ccc;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  height: 2em;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 0.7;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 2em;
  z-index: 1;
}

.splide__arrow svg {
  fill: #000;
  height: 1.2em;
  width: 1.2em;
}

.splide__arrow:hover:not(:disabled) {
  opacity: 0.9;
}

.splide__arrow:disabled {
  opacity: 0.3;
}

.splide__arrow:focus-visible {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide__arrow--prev {
  left: 1em;
}

.splide__arrow--prev svg {
  transform: scaleX(-1);
}

.splide__arrow--next {
  right: 1em;
}

.splide.is-focus-in .splide__arrow:focus {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide__pagination {
  bottom: 0.5em;
  left: 0;
  padding: 0 1em;
  position: absolute;
  right: 0;
  z-index: 1;
}

.splide__pagination__page {
  background: #ccc;
  border: 0;
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  margin: 3px;
  opacity: 0.7;
  padding: 0;
  position: relative;
  transition: transform 0.2s linear;
  width: 8px;
}

.splide__pagination__page.is-active {
  background: #fff;
  transform: scale(1.4);
  z-index: 1;
}

.splide__pagination__page:hover {
  cursor: pointer;
  opacity: 0.9;
}

.splide__pagination__page:focus-visible {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide.is-focus-in .splide__pagination__page:focus {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide__progress__bar {
  background: #ccc;
  height: 3px;
}

.splide__slide {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.splide__slide:focus {
  outline: 0;
}

@supports (outline-offset: -3px) {
  .splide__slide:focus-visible {
    outline: 3px solid #0bf;
    outline-offset: -3px;
  }
}
@media screen and (-ms-high-contrast: none) {
  .splide__slide:focus-visible {
    border: 3px solid #0bf;
  }
}
@supports (outline-offset: -3px) {
  .splide.is-focus-in .splide__slide:focus {
    outline: 3px solid #0bf;
    outline-offset: -3px;
  }
}
@media screen and (-ms-high-contrast: none) {
  .splide.is-focus-in .splide__slide:focus {
    border: 3px solid #0bf;
  }
  .splide.is-focus-in .splide__track > .splide__list > .splide__slide:focus {
    border-color: #0bf;
  }
}
.splide__toggle {
  cursor: pointer;
}

.splide__toggle:focus-visible {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide.is-focus-in .splide__toggle:focus {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide__track--nav > .splide__list > .splide__slide {
  border: 3px solid transparent;
  cursor: pointer;
}

.splide__track--nav > .splide__list > .splide__slide.is-active {
  border: 3px solid #000;
}

.splide__arrows--rtl .splide__arrow--prev {
  left: auto;
  right: 1em;
}

.splide__arrows--rtl .splide__arrow--prev svg {
  transform: scaleX(1);
}

.splide__arrows--rtl .splide__arrow--next {
  left: 1em;
  right: auto;
}

.splide__arrows--rtl .splide__arrow--next svg {
  transform: scaleX(-1);
}

.splide__arrows--ttb .splide__arrow {
  left: 50%;
  transform: translate(-50%);
}

.splide__arrows--ttb .splide__arrow--prev {
  top: 1em;
}

.splide__arrows--ttb .splide__arrow--prev svg {
  transform: rotate(-90deg);
}

.splide__arrows--ttb .splide__arrow--next {
  bottom: 1em;
  top: auto;
}

.splide__arrows--ttb .splide__arrow--next svg {
  transform: rotate(90deg);
}

.splide__pagination--ttb {
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  left: auto;
  padding: 1em 0;
  right: 0.5em;
  top: 0;
}

.modal {
  font-family: "Roboto", sans-serif;
}

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  padding: 1rem;
}

.modal__container {
  background-color: #ededed;
  padding: 5.5rem 2rem 2.5rem 2rem;
  max-width: 750px;
  max-height: 100vh;
  overflow-y: auto;
  box-sizing: border-box;
  position: relative;
}
@media (min-width: 768px) {
  .modal__container {
    padding: 4rem 6rem 4rem 3rem;
  }
}

.modal__header {
  font-size: 2.4rem;
  font-weight: 300;
  line-height: 1.15;
  margin: 0 0 2rem;
}
@media (min-width: 768px) {
  .modal__header {
    margin: 0 0 4rem;
    font-size: 3.2rem;
    line-height: 1.4;
  }
}

.modal__text {
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1.4;
  color: #868078;
  margin: 0 0 2rem;
}
@media (min-width: 768px) {
  .modal__text {
    margin: 0 0 4rem;
    font-size: 1.7rem;
  }
}

.modal__close {
  position: absolute;
  top: 2.5rem;
  right: 2rem;
  padding: 0;
  display: block;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  background: transparent;
  border: 0;
  margin: 0 0 0 auto;
}
@media (min-width: 768px) {
  .modal__close {
    top: 4rem;
    right: 3rem;
  }
}
.modal__close svg {
  width: 100%;
  height: 100%;
  pointer-events: none;
}

/**************************\
  Demo Animation Style
\**************************/
@keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes mmfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes mmslideIn {
  from {
    transform: translateY(15%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10%);
  }
}
.micromodal-slide {
  display: none;
}

.micromodal-slide.is-open {
  display: block;
}

.micromodal-slide[aria-hidden=false] .modal__overlay {
  animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden=false] .modal__container {
  animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden=true] .modal__overlay {
  animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden=true] .modal__container {
  animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
  will-change: transform;
}

.modal {
  display: none;
}

.modal.is-open {
  display: block;
}