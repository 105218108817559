/* variables */
//fonts
$font_size: 1.6rem;
$font_regular: 'Roboto', sans-serif;

//colors
$color_bg: #27201c;
$color_text: #868078;

$media_tablet: 768px;
$media_desktop: 992px;
$large_desktop: 1200px;
