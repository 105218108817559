.stages {
  padding: 9rem 0 0;
  background: linear-gradient(to top, #27201c 100px, #fff 100px);
  & .wrapper > p {
    max-width: 90rem;
    margin-bottom: 10rem;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1340px;
    margin: 0 auto;
  }
  &__list-item {
    counter-increment: section;
    padding: 3rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 5px 18px rgba(46, 46, 46, 0.03);
    border: 1px solid #dfdfdf;
    border-bottom: 5px solid #e49f46;
    @media (min-width: $media_tablet) {
      width: 49%;
      margin-bottom: 2.5rem;
    }
    &::before {
      content: counter(section);
      display: block;
      font-size: 10rem;
      padding-right: 3rem;
      font-weight: 700;
      color: #e49f46;
      line-height: 1;
    }
  }
  &__header {
    color: #402706;
    font-size: 1.8rem;
    font-weight: normal;
    margin-bottom: 1rem;
    @media (min-width: $large_desktop) {
      font-size: 2.4rem;
    }
  }
}
