.possibility {
  padding: 3rem 0;
  background: linear-gradient(to top, #27201c 70px, #fff 70px);
  @media (min-width: $media_tablet) {
    background: linear-gradient(to bottom, #fff 364px, #ededed 364px);
    padding: 3rem 0 8rem;
  }
  h2 {
    text-align: left;
    margin-bottom: 3rem;
    @media (min-width: $media_tablet) {
      text-align: center;
      margin-bottom: 8rem;
    }
  }
  .splide__main-text {
    margin-bottom: 1rem;
    @media (min-width: $media_tablet) {
      margin-bottom: 5rem;
    }
  }
}
