header {
  &.relative-header {
    position: relative;
    background-color: #27201c;
    height: 70px;
    @media (min-width: $media_tablet) {
      height: 90px;
    }
    @media (min-width: $large_desktop) {
      height: 130px;
    }
  }
  .header-wrapper {
    transition: padding 0.4s ease;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    @media (min-width: $media_tablet) {
      padding: 1rem 0;
    }
    @media (min-width: $large_desktop) {
      padding: 3rem 0;
    }
    &._fixed {
      animation-name: menuAnimate;
      animation-duration: 1s;
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      z-index: 20;
      padding: 0;
      background-color: #27201c;
      @media (max-width: 767px) {
        .menu_body {
          padding: 7rem 1rem 3rem;
        }
        .menu_body::before {
          height: 7rem;
        }
      }
    }
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 7rem;
  }
}
.menu-left {
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 1;
  @media (min-width: $media_tablet) {
    padding-right: 1rem;
    flex-grow: 0;
  }
  .social-container {
    display: none;
    @media (min-width: $media_tablet) {
      display: block;
    }
    a {
      @media (max-width: 1199px) {
        &:first-child {
          width: 1.4rem;
          height: 1.4rem;
          margin-right: 0.3rem;
        }
        width: 1.6rem;
        height: 1.6rem;
      }
    }
  }
  .menu_sub-list {
    @media (max-width: 767px) {
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      &::after,
      &::before {
        content: '';
        top: 2rem;
        right: 1rem;
        display: block;
        position: absolute;
        border: none;
        width: 2rem;
        height: 2px;
        background-color: #6c6262;
      }
      &::before {
        transform: rotate(45deg);
      }
      &::after {
        transform: rotate(135deg);
      }
    }
  }
}
.ico-phone {
  background-color: transparent;
  border: none;
  padding: 0;
  z-index: 5;
  display: block;
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 2rem;
  svg {
    width: 100%;
    height: 100%;
  }
  @media (min-width: $media_tablet) {
    display: none;
  }
}
.social-container {
  max-width: 10rem;
  a {
    margin-right: 0.5rem;
    display: inline-block;
    vertical-align: middle;
    svg {
      width: 100%;
      height: 100%;
    }
    &:last-child {
      margin-right: 0;
    }
    &:first-child {
      width: 1.9rem;
      height: 1.9rem;
      margin-right: 0.7rem;
    }
    width: 2.1rem;
    height: 2.1rem;
  }
}
.menu_logo {
  width: 100%;
  height: auto;
  max-width: 16.4rem;
  max-height: 4.1rem;
  display: block;
  padding-right: 2rem;
  @media (min-width: $large_desktop) {
    max-width: 24.4rem;
    max-height: 6rem;
    padding-right: 6rem;
  }
  a,
  img {
    width: 100%;
    height: auto;
  }
}
.menu_phone {
  color: #fff;
  position: relative;
  margin-right: 2rem;
  @media (min-width: $media_tablet) {
    font-size: 1.4rem;
    padding: 1rem 0;
  }
  @media (min-width: $large_desktop) {
    font-size: 1.6rem;
    margin-right: 4rem;
  }
  > span {
    cursor: pointer;
    display: none;
    @media (min-width: $media_tablet) {
      display: inline-block;
    }
  }
  .menu_sub-list {
    padding: 1rem 1rem 2.5rem 1rem;
    display: none;
    @media (min-width: $media_tablet) {
      display: block;
    }
    li {
      padding: 1.5rem;
      border-bottom: 1px solid #322a26;
    }
    span {
      display: block;
      margin-bottom: 0.5rem;
    }
  }
  .phone_city {
    color: #f69e29;
    font-size: 1.6rem;
  }
  .phone_schedule {
    color: #868078;
    font-size: 1.3rem;
  }
  .phone_mail {
    color: #868078;
    padding: 1.5rem 1rem;
    display: block;
  }
  .btn {
    width: 100%;
    font-size: 1.4rem;
    height: 4rem;
  }
}
.menu_body {
  z-index: 5;
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    display: block;
    position: fixed;
    left: 0;
    top: -100%;
    width: 100%;
    height: 100%;
    background-color: #1d1815;
    transition: top 0.4s ease;
    padding: 7rem 1rem 3rem;
    overflow: auto;
    &._bg {
      &:before {
        content: '';
        transition: all 0.3s ease;
        transition-delay: 1s;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 7rem;
        background-color: #1d1815;
        z-index: 20;
      }
    }
    &._active {
      top: 0%;
    }
  }
  @media (min-width: $media_tablet) {
    &:before {
      height: 12rem;
    }
  }
  > .btn {
    display: none;
    @media (min-width: $media_tablet) {
      display: block;
    }
  }
}
.menu_list {
  margin-bottom: 4rem;
  @media (min-width: $media_tablet) {
    margin-bottom: 0;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }
  * {
    color: #ffffff;
    font-size: 1.8rem;
    transition: color 0.4s ease;
    @media (min-width: $media_tablet) {
      font-size: 1.4rem;
    }
    @media (min-width: $large_desktop) {
      font-size: 1.6rem;
    }
  }
  > li {
    cursor: pointer;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    border-bottom: 1px solid #2f2824;
    @media (min-width: $media_tablet) {
      border-bottom: none;
      margin-right: 1.5rem;
    }
    a {
      display: block;
      padding: 2.5rem 0;
      text-align: left;
      @media (min-width: $media_tablet) {
        padding: 1rem 0;
      }
    }
  }
}
.menu_sub-list {
  > li {
    padding: 0;
    border-bottom: 1px solid #322a26;
    &:last-child {
      border-bottom: none;
    }
    a {
      @media (min-width: $media_tablet) {
        padding: 1.7rem 1rem;
      }
    }
  }
  z-index: 15;
  background-color: #1d1815;
  position: absolute;
  top: 100%;
  min-width: 30rem;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: all 0.3s ease 0s;
  transform: translate(0px, 10%);
  @media (min-width: $media_tablet) {
    min-width: 14rem;
    right: -2.8rem;
    padding: 0 1rem;
  }
}
.menu-right {
  z-index: 1;
  @media (max-width: 767px) {
    .menu_sub-list {
      background-color: #0e0b0a;
      flex: 1 1 100%;
      display: none;
      position: relative;
    }
  }
}
.menu-burger {
  z-index: 5;
  display: block;
  position: relative;
  width: 1.6rem;
  height: 1.4rem;
  cursor: pointer;
  & span,
  &::before,
  &::after {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    transition: all 0.4s ease-in-out;
    background-color: #f69e29;
    border-radius: 2px;
  }
  &::before,
  &::after {
    content: '';
  }
  &::before {
    top: 0;
  }
  &::after {
    bottom: 0;
  }
  & span {
    top: 50%;
    transform: scale(1) translate(0, -50%);
  }
  @media (min-width: $media_tablet) {
    display: none;
  }
  &._active {
    span {
      transform: scale(0) translate(0, -50%);
    }
    &::before {
      top: 50%;
      transform: rotate(-45deg) translate(0px, -50%);
    }
    &::after {
      bottom: 50%;
      transform: rotate(45deg) translate(0px, 50%);
    }
  }
}
.mobile-menu-info {
  display: block;
  @media (min-width: $media_tablet) {
    display: none;
  }
  > p,
  > a {
    color: #868078;
    font-size: 1.6rem;
    margin-bottom: 1rem;
    display: block;
    text-align: left;
  }
  .mobile-contact {
    padding-top: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
._pc {
  .menu_phone {
    &:hover {
      .menu_sub-list {
        opacity: 1;
        pointer-events: all;
        visibility: visible;
        transform: translate(-50%, -50%);
        @media (min-width: $media_tablet) {
          transform: translate(0px, 0px);
        }
      }
      .main_number {
        transition: all 0.3s ease;
        color: #868078;
      }
      .menu-arr {
        border-color: #868078;
        transform: rotate(135deg);
      }
    }
  }
  .menu_list {
    li:hover {
      .menu_sub-list {
        opacity: 1;
        pointer-events: all;
        visibility: visible;
        transform: translate(0px, 0px);
        display: block;
      }
      > a {
        color: #868078;
      }
      .menu-arr {
        border-color: #868078;
        transform: rotate(135deg);
      }
    }
  }
}
._touch {
  .menu_phone {
    &.activeArr {
      .menu_sub-list {
        opacity: 1;
        pointer-events: all;
        visibility: visible;
        transform: translate(-50%, -50%);
        @media (min-width: $media_tablet) {
          transform: translate(0px, 0px);
        }
      }
      .menu-arr {
        transform: rotate(135deg);
      }
    }
  }
  .menu_list {
    > .activeArr {
      .menu_sub-list {
        opacity: 1;
        pointer-events: all;
        visibility: visible;
        transform: translate(0px, 0px);
        display: block;
      }
      > .menu-arr {
        transform: rotate(135deg);
      }
    }
  }
}
.menu-arr {
  margin-left: 0.7rem;
  margin-bottom: 0.3rem;
  width: 0.6rem;
  height: 0.6rem;
  display: inline-block;
  vertical-align: middle;
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  transform: rotate(-45deg);
  transition: all 0.4s ease;
}

.decor-popup {
  &::before {
    content: '';
    top: -1.5rem;
    right: 1.9rem;
    position: absolute;
    width: 0;
    height: 0;
    border-left: 1.2rem solid transparent;
    border-right: 1.2rem solid transparent;
    border-bottom: 1.5rem solid #1d1815;
    display: none;
    @media (min-width: $media_tablet) {
      display: block;
    }
  }
}
@keyframes menuAnimate {
  0% {
    top: -100%;
  }
  75% {
    top: 0;
  }
}
