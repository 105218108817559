.calculate-form {
  padding: 3rem 0;
  position: relative;
  z-index: 1;
  background: linear-gradient(to bottom, #f69e29 65%, #fff 65%);
  &.dark {
    background: linear-gradient(to bottom, #f69e29 65%, #27201c 65%);
  }
  @media (min-width: $media_tablet) {
    padding: 8rem 0 10rem;
  }
  p {
    color: #161210;
  }
  .column {
    &:first-child {
      @media (min-width: $media_tablet) {
        max-width: 57rem;
      }
    }
  }
  .calculate-form-wrapper {
    background-color: #fff;
    padding: 2rem 1.7rem;
    box-shadow: 0px 5px 18px rgba(46, 46, 46, 0.14);
    border: 1px solid #e8e8e8;
    margin-top: 3rem;
    @media (min-width: $media_tablet) {
      padding: 6rem 2rem;
      margin-top: 8rem;
    }
    .tabs__form {
      max-width: 1340px;
      margin: 0 auto;
    }
  }
  .form__input {
    input {
      background-color: #f5f5f5;
      border: 2px solid #c6c6c6;
      padding: 2.5rem 1.5rem;
    }
    label {
      padding: 2.5rem 1.5rem;
    }
  }
  .form__btn {
    height: 6rem;
    @media (min-width: $media_tablet) {
      height: 8rem;
    }
  }
}
