.table {
  padding: 4rem 0 0;
  @media (min-width: $media_tablet) {
    padding: 7rem 0 0;
  }
  background-color: #f6f6f6;
  .table-wrapper {
    width: 100%;
    max-width: 1340px;
    margin: 0 auto;
    position: relative;
    padding-bottom: 3.5rem;
  }
  p {
    margin-bottom: 0;
    padding-bottom: 3.5rem;
  }
  .table-conteiner {
    overflow: auto;
    position: relative;
    // -ms-overflow-style: none;
    // scrollbar-width: none;
    // padding-left: 40rem;
  }
  //   .table-conteiner::-webkit-scrollbar {
  //     width: 0;
  //     height: 0;
  //   }
  table {
    width: 100%;
    word-break: break-all;
  }
  thead {
    background-color: #f69e29;
  }

  th,
  td {
    text-align: left;
    //max-width: 20rem;

    width: auto;
    padding: 1.2rem 1.7rem;
    font-size: 1.4rem;
    // @media (min-width: 480px) {
    //   min-width: calc((100vw - 16rem) / 2);
    // }
    min-width: 20rem;
    @media (min-width: $large_desktop) {
      padding: 3rem 1rem;
      font-size: 2.4rem;
      //   width: 25%;
    }
  }
  th {
    font-weight: normal;
    color: #161210;
    &.fixed-td {
      background-color: #f69e29;
    }
  }
  td {
    background-color: #f6f6f6;
    font-weight: 300;
    color: #5c5751;
    border: none;
  }

  tr {
    border-bottom: 1px solid #b1b1b1;
  }
  .custom-scroll {
    thead {
      background-color: #27201c;
    }
    th {
      color: #fff;
    }
    td {
      background-color: #ebebeb;
      &.fixed-td {
        background-color: #f6f6f6;
      }
    }

    td {
      min-width: calc((100vw - 16rem) / 2);
      @media (min-width: 400px) {
        min-width: calc(100vw / 2);
      }
      @media (min-width: $media_tablet) {
        min-width: calc(100vw / 3);
      }
      @media (min-width: 1360px) {
        min-width: calc(1340px / 4);
      }
    }
    .fixed-td {
      min-width: 16rem;
      @media (min-width: 400px) {
        min-width: calc(100vw / 2);
      }
      @media (min-width: $media_tablet) {
        min-width: calc(100vw / 3);
      }
      @media (min-width: 1360px) {
        min-width: calc(1340px / 4);
      }
      position: sticky;
      left: 0;
      top: 0;
      //   @media (min-width: $large_desktop) {
      //     min-width: calc((100vw - 16rem) / 3);
      //   }
    }
    & ~ .table-nav {
      display: flex;
    }
  }
  .table-nav {
    background-color: #27201c;
    display: none;
    justify-content: space-between;
    align-items: center;
    top: 0;
    right: 0;
    padding: 1rem 0;

    position: absolute;
    z-index: 1;
    @media (min-width: $large_desktop) {
      padding: 3.5rem 2rem;
    }
  }
  .tableArr {
    width: 1.5rem;
    height: 2rem;
    margin: 0 0.7rem;
    cursor: pointer;
    svg {
      width: 100%;
      height: 100%;
      display: block;
    }

    &.moveLeft {
      transform: rotate(180deg);
    }
  }
}
